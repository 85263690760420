/** @format */

import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { BottomNavItems } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'src/components/avatar';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { helpButtonAction } from 'src/store/action/bottomNavActions';
import { CLICK_NAVIGATION_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const BottomNav = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, picture } = useAuth();
  let navigation = useNavigate();

  const toggleDrawer = (openEvent, event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(helpButtonAction(openEvent));
    if (!openEvent) navigation(-1);
  };

  return (
    <Box className={`wrapper_link_bottom`}>
      <NavLink
        className={`link_icon profile`}
        to="/profile"
        onClick={() => {
          dataLayerPushEvent(CLICK_NAVIGATION_EVENT, {
            navigation_item: 'Profile'
          });
        }}>
        <Avatar srcImg={user?.image || picture || ''} name={`${user?.firstName} ${user?.lastName}`} />
        <span className={`link_icon-text`}>{t('menu.profile')}</span>
      </NavLink>
      {user && user.isAdmin && (
        <>
          <NavLink
            className="link_icon"
            to="/admin"
            onClick={() => {
              dataLayerPushEvent(CLICK_NAVIGATION_EVENT, {
                navigation_item: 'Admin'
              });
            }}>
            <span className={`link_icon-text`}>{t('menu.adminUser')}</span>
          </NavLink>
        </>
      )}
      {BottomNavItems.map((item, i) => {
        return (
          <NavLink
            key={i}
            className={`link_icon`}
            to={item.path}
            onClick={event => {
              toggleDrawer(true, event);
              dataLayerPushEvent(CLICK_NAVIGATION_EVENT, {
                navigation_item: item.title
              });
            }}>
            <span className={`link_icon-text`}>
              {item.title === 'Help' && t('menu.help')}
              {item.title === 'Portals' && t('menu.portals')}
            </span>
          </NavLink>
        );
      })}
    </Box>
  );
};

export default BottomNav;

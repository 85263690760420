/** @format */

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ComponentTools } from './comTools';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import UploadDocuments from '../uploadDocuments';
import { useState } from 'react';
import { COMPONENT_ACTION } from 'src/constants';

const allowedContentTypes = [
  'video/mp4',
  'video/quicktime', // .mov
  'video/x-msvideo', // .avi
  'video/x-ms-wmv', // .wmv
  'video/mpeg', // .mpeg, .mpg
  'video/webm', // .webm
  'video/ogg', // .ogv
  'video/x-matroska' // .mkv
];

export default function ComVideo({ components, actionComponent, com, index }) {
  const { t } = useTranslation();
  const [error, setError] = useState({
    title: false,
    description: false,
    video: false
  });

  const fieldValidate = e => {
    const { name, value } = e;
    setError(_error => ({ ...error, [name]: !value }));
  };
  const setContactValue = (e, name) => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, {
      component: com,
      value: com.value ? { ...com.value, [name]: e.target.value } : { [name]: e.target.value }
    });
  };
  const setVideoData = data => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, {
      component: com,
      value: com.value ? { ...com.value, video: data } : { video: data }
    });
  };

  return (
    <Box className="pageBuilder-contentFieldsWrap">
      <Box className="field-label">
        <span>{t('editorContent.content.video')}*</span>
        <ComponentTools components={components} action={actionComponent} component={com} />
      </Box>
      <Box className="fields-column-direction">
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.videoTitle')}*`}
          name={`video-title-${index}`}
          onChange={e => setContactValue(e, 'title')}
          defaultValue={com.value?.title || ''}
          onBlur={e => fieldValidate({ name: 'title', value: e.target.value })}
          error={error.title}
        />
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.videoDescription')}*`}
          name={`video-description-${index}`}
          onChange={e => setContactValue(e, 'description')}
          defaultValue={com.value?.description || ''}
          onBlur={e => fieldValidate({ name: 'description', value: e.target.value })}
          error={error.description}
        />
        <UploadDocuments
          name={`video-upload-${index}`}
          setDataByName={(name, value) => {
            if (name === `video-upload-${index}`) setVideoData(value);
            else if (name === `errUploadDocument`) setError(_error => ({ ..._error, video: value }));
          }}
          accept={allowedContentTypes}
          dataValue={com.value?.video ? com.value.video : []}
          type="video"
          errUploadDocument={error.video}
          filesLimit={1}
        />
      </Box>
    </Box>
  );
}

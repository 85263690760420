/** @format */

import { css } from '@emotion/css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Modal, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IconLabelButtons from 'src/components/Buttons';
import { SearchHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import useAuth from 'src/hooks/useAuth';
import { getUserRoleByLang } from 'src/store/action/admin.Action';
import { getUserList, nextUserList } from 'src/store/action/user.Action';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { UserItem } from './UserItem';
import { marketMap } from 'src/utils/market';
import Loader from 'src/components/Loader';
import { getRolesList } from 'src/store/action/editor.Action';

const customCSS = {
  previewLayer: css`
    &.MuiPaper-root {
      background: #fffdfa;
      padding: 40px 60px;
      border-radius: 40px 0 0 40px;
      width: 90%;
      @media screen and (max-width: 768px) {
        padding: 20px 16px;
      }
    }
  `,
  layerCloseButton: css`
    &.close-button {
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }
  `,
  heading: css`
    margin-top: 40px;
  `,
  cardsWrap: css`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  `,
  postHeading: css`
    margin-top: 8px;
  `
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const LabelSorting = ({ label, name, sortBy, setSortBy }) => {
  return (
    <span
      className={`sort-label ${sortBy.sortBy === name ? `sort-label-active` : ``}`}
      onClick={() => {
        if (sortBy.sortBy !== name || sortBy.sort === 'DESC') {
          setSortBy({ sortBy: name, sort: 'ASC' });
        } else {
          setSortBy({ sortBy: name, sort: 'DESC' });
        }
      }}>
      {label}
      {sortBy.sortBy === name && (
        <>
          {sortBy.sort === 'ASC' && <ArrowDropUpIcon />}
          {sortBy.sort === 'DESC' && <ArrowDropDownIcon />}
        </>
      )}
    </span>
  );
};

export default function AdminUsers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState();
  const roles = useSelector(state => state.editorReducer.rolesList);
  const allRoles = useSelector(state => state.adminReducer.roles);
  const userList = useSelector(state => [...state.userReducer.userList]);
  const userListPayload = useSelector(state => state.userReducer.userListPayload);
  const loading = useSelector(state => state.userReducer.loading);
  const nextCursor = useSelector(state => state.userReducer.nextUserCursor);
  const navigate = useNavigate();
  const [isModalOpen, setDeleteModal] = useState(false);
  const [sortBy, setSortBy] = useState({ sortBy: 'created', sort: 'DESC' });
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);

  useEffect(() => {
    dispatch(getUserList(userListPayload));
  }, [dispatch, userListPayload]);

  useEffect(() => {
    if (!user.isAdmin) {
      navigate('/dashboard');
    }
    if (!user.roles?.includes('Extranet Useradmin')) {
      navigate('/dashboard');
    }

    dispatch(navigationOpenAction(false));

    if (!roles || roles.length === 0) {
      dispatch(
        getRolesList({
          lang: user ? user.locale : 'en'
        })
      ).then(() => {
        // Load all roles per each market
        for (let [market] of marketMap) {
          if (!allRoles[market]) {
            dispatch(getUserRoleByLang({ lang: market }));
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const approveUser = ({ user, market, roles }) => {};

  const rejectUser = (confirm, uuid) => {};

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextCursor) {
          const payload = {
            ...userListPayload,
            cursor: nextCursor
          };
          dispatch(nextUserList(payload));
        }
      });

      if (post) intObserver.current.observe(post);
    },
    // eslint-disable-next-line
    [nextCursor]
  );
  // Pagination End

  switch (sortBy.sortBy) {
    case 'company':
      if (sortBy.sort === 'ASC')
        userList.sort((a, b) => {
          const _a = { ...a, company: a.company || '' };
          const _b = { ...b, company: b.company || '' };
          return _a.company.localeCompare(_b.company);
        });
      if (sortBy.sort === 'DESC')
        userList.sort((a, b) => {
          const _a = { ...a, company: a.company || '' };
          const _b = { ...b, company: b.company || '' };
          return _b.company.localeCompare(_a.company);
        });
      break;
    case 'firstName':
      if (sortBy.sort === 'ASC') userList.sort((a, b) => a.firstName.localeCompare(b.firstName));
      if (sortBy.sort === 'DESC') userList.sort((a, b) => b.firstName.localeCompare(a.firstName));
      break;
    case 'created':
      if (sortBy.sort === 'ASC') userList.sort((a, b) => a.created - b.created);
      if (sortBy.sort === 'DESC') userList.sort((a, b) => b.created - a.created);
      break;
    case 'email':
      if (sortBy.sort === 'ASC') userList.sort((a, b) => a.email.localeCompare(b.email));
      if (sortBy.sort === 'DESC') userList.sort((a, b) => b.email.localeCompare(a.email));
      break;
    default:
      userList.sort((a, b) => a.created - b.created);
      break;
  }

  return (
    <div className={`${checkNavOpen ? `admin-user-nav-open` : ``}`}>
      <SearchHeader heading={t('user.heading')} />
      <ScrollAble marginTop="34px" className={`admin-user`}>
        <Box className="user-list extranet-user-list">
          {/* head table */}
          <Box className="heading-column">
            <Box className="role">{t('admin.role')}</Box>
            <Box className="locale">{t('admin.locale')}</Box>
            {!checkNavOpen && (
              <Box className="company">
                <LabelSorting label={t('admin.company')} name="company" sortBy={sortBy} setSortBy={setSortBy} />
              </Box>
            )}
            <Box className="f-name">
              <LabelSorting label={t('admin.name')} name="firstName" sortBy={sortBy} setSortBy={setSortBy} />
            </Box>
            {!checkNavOpen && (
              <Box className="email">
                <LabelSorting label={t('admin.email')} name="email" sortBy={sortBy} setSortBy={setSortBy} />
              </Box>
            )}
            {!checkNavOpen && (
              <Box className="register-date">
                <LabelSorting
                  label={t('admin.registrations_date')}
                  name="created"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Box>
            )}
            <Box className="action">{t('admin.actions')}</Box>
          </Box>
          {/* item list */}
          {loading ? (
            <Loader />
          ) : userList.length > 0 ? (
            userList.map((item, i) => {
              const isPaginationItem = userList.length === i + 1;
              return (
                <UserItem
                  key={item.uuid}
                  i={i}
                  user={item}
                  lastPostRef={isPaginationItem ? lastPostRef : undefined}
                  approveUser={approveUser}
                  rejectUser={rejectUser}
                  customCSS={customCSS}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              );
            })
          ) : (
            <Box className="admin-user-no-result">{t('searchContent.noResult')}</Box>
          )}
        </Box>
      </ScrollAble>
      <Modal open={isModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('admin.rejectConfirmation.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('admin.rejectConfirmation.description')}
          </Typography>
          <Box sx={{ mt: 4, justifyContent: 'space-between', display: 'flex' }}>
            <IconLabelButtons
              type="button"
              label={t('admin.rejectConfirmation.no')}
              className="customButton dark"
              onClick={() => setDeleteModal(false)}
            />
            <IconLabelButtons
              type="button"
              label={t('admin.rejectConfirmation.yes')}
              className="customButton dark"
              onClick={() => rejectUser(true)}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  adminTopicsDeleteTopic,
  adminTopicsEdit,
  adminTopicsEditorOpen,
  createTopic,
  deleteTopicClause,
  resetFilter,
  searchTopicClause,
  setFilter,
  updateTopic,
  validateNameTopic
} from '../action/adminTopics.Action';

const initialState = {
  errors: [],
  openState: false,
  idTopicEdit: null,
  idDeleteTopic: null,
  topicClauses: [],
  filter: { role: [], brand: [], status: null }
};

const adminTopicsReducer = createReducer(initialState, builder => {
  builder
    .addCase(adminTopicsEditorOpen, (state, action) => {
      state.openState = action.payload;
    })
    .addCase(adminTopicsEdit, (state, action) => {
      state.idTopicEdit = action.payload;
    })
    .addCase(adminTopicsDeleteTopic, (state, action) => {
      state.idDeleteTopic = action.payload;
    })
    .addCase(searchTopicClause, (state, action) => {
      state.topicClauses = [...state.topicClauses, action.payload];
    })
    .addCase(deleteTopicClause, (state, action) => {
      state.topicClauses = state.topicClauses.filter(clause => clause !== action.payload);
    })
    .addCase(setFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(resetFilter, (state, action) => {
      state.filter = { role: [], brand: [], status: null };
      state.topicClauses = [];
    })
    .addCase(createTopic.fulfilled, (state, action) => {
      if (action.payload.status === 400) {
        state.errors = action.payload.data.errors;
      } else {
        state.errors = [];
      }
    })
    .addCase(updateTopic.fulfilled, (state, action) => {
      if (action.payload.status === 400) {
        state.errors = action.payload.data.errors;
      } else {
        state.errors = [];
      }
    })
    .addCase(validateNameTopic.fulfilled, (state, action) => {
      if (action.payload.status === 400) {
        state.errors = action.payload.data.errors;
      } else {
        state.errors = [];
      }
    })
    .addDefaultCase((state, action) => {});
});

export default adminTopicsReducer;

/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import {
  ADMIN_TOPICS_EDITOR_OPEN,
  ADMIN_TOPICS_EDITOR_TOPIC,
  ADMIN_TOPICS_DELETE_TOPIC,
  SEARCH_TOPIC_CLAUSE,
  DELETE_TOPIC_CLAUSE,
  CREATE_TOPIC,
  UPDATE_TOPIC,
  CHECK_TOPIC_NAME,
  FILTER_TOPIC,
  RESET_FILTER_TOPIC
} from './type.actions';

export const adminTopicsEditorOpen = createAction(ADMIN_TOPICS_EDITOR_OPEN);
export const adminTopicsEdit = createAction(ADMIN_TOPICS_EDITOR_TOPIC);
export const adminTopicsDeleteTopic = createAction(ADMIN_TOPICS_DELETE_TOPIC);
export const searchTopicClause = createAction(SEARCH_TOPIC_CLAUSE);
export const deleteTopicClause = createAction(DELETE_TOPIC_CLAUSE);
export const setFilter = createAction(FILTER_TOPIC);
export const resetFilter = createAction(RESET_FILTER_TOPIC);

export const createTopic = createAsyncThunk(CREATE_TOPIC, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang,
      'Content-type': 'application/json'
    };
    const response = await axiosInstance.post(`/topics`, payload.data, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});
export const updateTopic = createAsyncThunk(UPDATE_TOPIC, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang,
      'Content-type': 'application/json'
    };
    const response = await axiosInstance.put(`/topics/${payload.id}`, payload.data, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});
export const validateNameTopic = createAsyncThunk(CHECK_TOPIC_NAME, async payload => {
  try {
    const headers = {
      'Accept-Language': payload.lang,
      'Content-type': 'application/json'
    };
    const response = await axiosInstance.get(`/topics/check?name=${payload.name}`, { headers });
    return response.data;
  } catch (error) {
    return error;
  }
});

/** @format */

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import AutocompleteSuggestion from 'src/components/Autocomplete/AutocompleteSuggestion';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import { css, cx } from '@emotion/css';
import { useDispatch, useSelector } from 'react-redux';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { formatISO9075 } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MultiSelectTree from 'src/components/MultiSelectTree';
import { getInfoTypeList } from '../../../store/action/editor.Action';
import useAuth from '../../../hooks/useAuth';
import { getTagSuggestions } from '../../../requests/editor';
import { debounce } from 'lodash';
import { AutoCompleteSelectLight } from '../../../components/Dropdowns/LightDropdown';
import InputChips from 'src/components/InputChips';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import IconLabelButtons from 'src/components/Buttons';
import { CheckIcon } from 'src/components/Icons';
import RadioBox from 'src/components/Radio';
import { CheckLabelTransparentLight } from 'src/components/Checkbox';

const groupTargets = {
  s1: [
    { name: 'Regional Sales Offices', value: 'Regional Sales Offices' },
    { name: 'National Dealer', value: 'National Dealer' },
    { name: 'Dealer', value: 'Dealer' },
    { name: 'Special vehicles Dealer', value: 'Special vehicles Dealer' },
    { name: 'Workshop VC (LKW)', value: 'Workshop VC (LKW)' }
  ],
  s2: [
    { name: 'Regional Sales Offices', value: 'Regional Sales Offices' },
    { name: 'National Dealer', value: 'National Dealer' },
    { name: 'Dealer', value: 'Dealer' },
    { name: 'Workshop PV (PKW)', value: 'Workshop PV (PKW)' },
    { name: 'Workshop PV (DRS)', value: 'Workshop PV (DRS)' }
  ]
};

const EditorDistribution = props => {
  const { postData, postType } = props;
  const { t } = useTranslation();
  const infoTypeList = useSelector(state => state.editorReducer.infoTypeList).map(o => ({
    ...o,
    name: t('infoTypes.' + o.name)
  }));
  const [releaseDate, setReleaseDate] = useState(postData?.releaseDate ? postData.releaseDate : undefined);
  const [expiryDate, setExpiryDate] = useState(postData?.expiryDate ? postData.expiryDate : undefined);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const marketList = useSelector(state => state.editorReducer.marketList);

  useEffect(() => {
    if ((postType === 'content' || postType === 'pageBuilder') && infoTypeList && infoTypeList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getInfoTypeList(payload));
    }
    // eslint-disable-next-line
  }, []);

  const customCSS = {
    mainContent: css`
      height: calc(100vh - 160px - 120px - 20px);
      overflow: auto;
      margin-top: 160px;
      padding: 40px 194px 0 202px;
      @media screen and (max-width: 1200px) {
        padding: 0 36px;
      }
      @media screen and (max-width: 899px) {
        margin-top: 0;
        padding: 0;
        height: calc(100vh - 253px - 120px - 50px);
      }
    `,
    contentFieldsWrap: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 64px;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        margin-top: 0;
      }

      > div {
        &:nth-of-type(1) {
          flex-basis: calc(100% - 970px - 24px);
          min-width: 160px;
          @media screen and (max-width: 1440px) {
            flex-basis: calc(100% - 600px - 24px);
          }
          @media screen and (max-width: 767px) {
            flex-basis: 100%;
          }
        }

        &:nth-of-type(2) {
          flex-basis: 970px;
          margin-left: 24px;
          @media screen and (max-width: 1440px) {
            flex-basis: 600px;
          }
          @media screen and (max-width: 767px) {
            flex-basis: 100% !important;
            margin: 16px 0 32px 0;
          }
        }
      }

      &:last-of-type {
        > div {
          &:nth-of-type(2) {
            // margin-bottom: 10px;
          }
        }
      }
    `,
    selection: css`
      width: 100%;
      position: relative;
      @media screen and (min-width: 900px) {
        width: calc(50% - 13px);
      }
    `,
    targetGroupFlex: css`
      display: flex;
      gap: 80px;
    `,
    targetGroupFlexItem: css``,
    radioBox: css`
      cursor: pointer;
      margin-bottom: 8px;
    `
  };

  const handleActiveNotifications = active => {
    props.setDataByName('activateNotifications', active);
    setOpenDialog(false);
  };

  const targetGroupsCheckHandle = e => {
    const value = e.target.value;
    let targetGroups = postData.targetGroups ? [...postData.targetGroups] : [];
    if (e.target.checked) {
      targetGroups.push(value);
    } else {
      targetGroups = targetGroups.filter(group => group !== value);
    }
    props.setDataByName('targetGroups', targetGroups);
  };

  return (
    <Box className={cx(customCSS.mainContent)}>
      <Box className={cx(customCSS.contentFieldsWrap)}>
        <Box className="field-label">
          <Box className="field-label-name">{t('editorContent.postSetting.Select_Target_market')}*</Box>
          <Box className="field-label-helper">{t('editorContent.postSetting.Select_Target_market_helper')}</Box>
        </Box>
        <Box>
          <Box className={cx(customCSS.selection)}>
            <Box>
              <SwitchLabel
                label={t('editorContent.postSetting.allMarkets')}
                name="allMarkets"
                onChange={e => {
                  if (!postData.allMarkets) props.setDataByName('markets', []);
                  props.setDataByName('allMarkets', !postData.allMarkets);
                }}
                checked={!!postData?.allMarkets}
              />
            </Box>
            <Box sx={{ mt: 2 }} style={!!postData.allMarkets ? { opacity: '0.3', pointerEvent: 'none' } : null}>
              <MultiSelectDropdownLight
                options={marketList.map(locale => ({ name: locale.market, id: locale.locale }))}
                name="markets"
                disabled={!!postData.allMarkets}
                setdatabyname={(name, value) => {
                  props.setDataByName(name, value);
                }}
                customclass={cx(customCSS.marketSelection)}
                error={false}
                defaultValue={postData?.markets ? postData.markets : []}
                value={postData?.markets ? postData.markets : []}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={cx(customCSS.contentFieldsWrap)}>
        <Box className="field-label">
          <Box className="field-label-name">{t('editorContent.postSetting.Select_Target_audience')}*</Box>
          <Box className="field-label-helper">{t('editorContent.postSetting.Select_Target_audience_helper')}</Box>
        </Box>
        <Box>
          <Box className={cx(customCSS.targetGroupFlex)}>
            <Box className={cx(customCSS.targetGroupFlexItem)}>
              <Box className={cx(customCSS.radioBox)}>
                <RadioBox
                  label={t('editorContent.postSetting.targetGroupSelection01')}
                  name="targetGroupSelection"
                  boxProps={{
                    onChange: () => {
                      props.setDataByName(
                        'targetGroupSelection',
                        t('editorContent.postSetting.targetGroupSelection01')
                      );
                      props.setDataByName('targetGroups', []);
                    }
                  }}
                  inputProps={{}}
                  checked={postData?.targetGroupSelection === t('editorContent.postSetting.targetGroupSelection01')}
                />
              </Box>
              <Box
                style={
                  postData.targetGroupSelection !== t('editorContent.postSetting.targetGroupSelection01')
                    ? { opacity: '0.3', pointerEvent: 'none' }
                    : null
                }>
                {groupTargets.s2.map(target => {
                  const checked =
                    (postData?.targetGroups?.includes(target.value) &&
                      postData?.targetGroupSelection === t('editorContent.postSetting.targetGroupSelection01')) ||
                    false;
                  return (
                    <Box key={target.value}>
                      <CheckLabelTransparentLight
                        label={target.name}
                        checked={checked}
                        value={target.value}
                        name="targetGroups"
                        onChange={targetGroupsCheckHandle}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className={cx(customCSS.targetGroupFlexItem)}>
              <Box className={cx(customCSS.radioBox)}>
                <RadioBox
                  label={t('editorContent.postSetting.targetGroupSelection02')}
                  name="targetGroupSelection"
                  boxProps={{
                    onChange: () => {
                      props.setDataByName(
                        'targetGroupSelection',
                        t('editorContent.postSetting.targetGroupSelection02')
                      );
                      props.setDataByName('targetGroups', []);
                    }
                  }}
                  inputProps={{}}
                  checked={postData?.targetGroupSelection === t('editorContent.postSetting.targetGroupSelection02')}
                />
              </Box>
              <Box
                style={
                  postData?.targetGroupSelection !== t('editorContent.postSetting.targetGroupSelection02')
                    ? { opacity: '0.3', pointerEvent: 'none' }
                    : null
                }>
                {groupTargets.s1.map(target => {
                  const checked =
                    (postData?.targetGroups?.includes(target.value) &&
                      postData?.targetGroupSelection === t('editorContent.postSetting.targetGroupSelection02')) ||
                    false;
                  return (
                    <Box key={target.value}>
                      <CheckLabelTransparentLight
                        label={target.name}
                        checked={checked}
                        value={target.value}
                        name="targetGroups"
                        onChange={targetGroupsCheckHandle}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {postType === 'news' && (
        <Box className={cx(customCSS.contentFieldsWrap)} sx={{ mt: '60px' }}>
          <Box className="field-label">{t('editorContent.postSetting.pinnedNews')}</Box>
          <Box>
            <SwitchLabel name="isPinned" onChange={props.setNotification} checked={postData?.isPinned} />
          </Box>
        </Box>
      )}
      <Box className={cx(customCSS.contentFieldsWrap)} sx={{ mt: '60px' }}>
        <Box className="field-label">{t('editorContent.postSetting.Activate_Notifications')}</Box>
        <Box>
          <SwitchLabel
            label={t('editorContent.content.nortificationActive')}
            name="activateNotifications"
            onChange={e => {
              if (!postData?.activateNotifications) {
                setOpenDialog(true);
              }
              props.setNotification(e);
            }}
            checked={postData?.activateNotifications || false}
          />
        </Box>
      </Box>
      {(postType === 'content' || postType === 'pageBuilder') && (
        <>
          <Box className={cx(customCSS.contentFieldsWrap)} sx={{ mt: '60px' }}>
            <Box className="field-label">{t('editorContent.postSetting.Activate_CASALink')}</Box>
            <Box>
              <SwitchLabel
                label={t('editorContent.content.CASALinkActive')}
                name="casaLinkActive"
                onChange={e => {
                  props.setDataByName('casaLinkActive', e.target.checked);
                }}
                checked={postData?.casaLinkActive || false}
              />
            </Box>
          </Box>
          {postData?.casaLinkActive && (
            <>
              <Box className={cx(customCSS.contentFieldsWrap)}>
                <Box className="field-label">{t('editorContent.postSetting.CASAPartNumber')}*</Box>
                <Box>
                  <InputChips
                    name="casaPartNumbers"
                    value={postData?.casaPartNumbers || []}
                    setdatabyname={props.setDataByName}
                    placeholder={t('editorContent.postSetting.CASAInputPlaceholder')}
                  />
                </Box>
              </Box>
              <Box className={cx(customCSS.contentFieldsWrap)}>
                <Box className="field-label">{t('editorContent.postSetting.CASAProductHirarchy')}*</Box>
                <Box>
                  <InputChips
                    name="casaHierarchies"
                    value={postData?.casaHierarchies || []}
                    setdatabyname={props.setDataByName}
                    placeholder={t('editorContent.postSetting.CASAInputPlaceholder')}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      <Box className={cx(customCSS.contentFieldsWrap)}>
        <Box className="field-label">{t('editorContent.postSetting.Publishing_date')}*</Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: 'calc(50% - 13px)' }} className="datePickerContainer light">
            <MobileDatePicker
              value={releaseDate === undefined ? t('editorContent.postSetting.dateFormart') : releaseDate}
              maxDate={expiryDate ? expiryDate : undefined}
              onChange={newValue => {
                setReleaseDate(newValue);
                props.setDataByName('releaseDate', formatISO9075(new Date(newValue), { representation: 'date' }));
              }}
              closeOnSelect={true}
              renderInput={params => (
                <OutlinedInputFieldsLight
                  sx={{ flex: 1 }}
                  {...params}
                  value="dd.mm.yyyy"
                  placeholder={t('editorContent.postSetting.dateFormart')}
                />
              )}
            />
          </Box>
          {postType === 'marketing' && (
            <Box sx={{ width: 'calc(50% - 13px)', ml: '26px' }} className="datePickerContainer light">
              <MobileDatePicker
                value={expiryDate === undefined ? t('editorContent.postSetting.dateFormart') : expiryDate}
                closeOnSelect={true}
                minDate={releaseDate ? releaseDate : undefined}
                onChange={newValue => {
                  setExpiryDate(newValue);
                  props.setDataByName('expiryDate', formatISO9075(new Date(newValue), { representation: 'date' }));
                }}
                renderInput={params => (
                  <OutlinedInputFieldsLight
                    sx={{ flex: 1 }}
                    {...params}
                    value="dd.mm.yyyy"
                    placeholder={t('editorContent.postSetting.dateFormart')}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        className="dialog-editor"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('editorBase.sendNotifications')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('editorBase.sendNotificationsConfirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-editor-footer">
          <IconLabelButtons
            type="button"
            label={t('no')}
            className="customButton light"
            onClick={() => handleActiveNotifications(false)}
          />
          <IconLabelButtons
            endIcon={<CheckIcon />}
            type="button"
            label={t('yes')}
            className="customButton dark"
            onClick={() => handleActiveNotifications(true)}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditorDistribution;

/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  editorStepAction,
  editorOpen,
  previewStepAction,
  previewOpen,
  saveNewsArticle,
  getRolesList,
  getContactDepartmentList,
  getTagList,
  saveMarketingPost,
  getMarketList,
  getEditorsList,
  filtersearchOption,
  filterdeleteSearchOption,
  filterclearFilters,
  cloneContentPost,
  editContentPost,
  editMarketingPost,
  editNewsPost,
  clearData,
  saveContentPost,
  getInfoTypeList,
  cloneNewsPost,
  cloneMarketingPost,
  savePageBuilderPost
} from 'src/store/action/editor.Action';

const initialState = {
  activeStep: 1,
  openState: false,
  previewOpenState: false,
  newsPostSaveData: undefined,
  contentPostSave: undefined,
  pageBuilderPostSave: undefined,
  infoTypeList: [],
  rolesList: [],
  departmentList: [],
  tagList: [],
  marketingPostSave: undefined,
  marketList: [],
  editorsList: [],
  filterClauses: [],
  contentPostClone: undefined,
  newsPostClone: undefined,
  marketingPostClone: undefined,
  contentPostEdit: undefined,
  newsPostEdit: undefined,
  marketingPostEdit: undefined,
  marketingPostUpdated: undefined,
  newsPostUpdated: undefined,
  contentPostUpdated: undefined
};

const editorReducer = createReducer(initialState, builder => {
  builder
    .addCase(editorStepAction, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(editorOpen, (state, action) => {
      state.openState = action.payload;
    })
    .addCase(previewStepAction, (state, action) => {
      state.previewActiveStep = action.payload;
    })
    .addCase(previewOpen, (state, action) => {
      state.previewOpenState = action.payload;
    })
    // Create Post Case
    .addCase(saveNewsArticle.fulfilled, (state, action) => {
      state.newsPostSaveData = action.payload;
    })
    .addCase(saveNewsArticle.rejected, (state, action) => {
      state.newsPostSaveError = action.meta;
    })
    .addCase(saveMarketingPost.fulfilled, (state, action) => {
      state.marketingPostSave = action.payload;
    })
    .addCase(saveContentPost.fulfilled, (state, action) => {
      state.contentPostSave = action.payload;
    })
    .addCase(savePageBuilderPost.fulfilled, (state, action) => {
      state.pageBuilderPostSave = action.payload;
    })
    .addCase(getInfoTypeList.fulfilled, (state, action) => {
      const infoTypes = action.payload.infoTypes ?? initialState.infoTypeList;
      state.infoTypeList = infoTypes.sort((a, b) => a.name.localeCompare(b.name));
    })
    .addCase(getRolesList.fulfilled, (state, action) => {
      state.rolesList = action.payload.roles ?? initialState.rolesList;
    })
    .addCase(getContactDepartmentList.fulfilled, (state, action) => {
      state.departmentList = action.payload.departments ?? initialState.departmentList;
    })
    .addCase(getTagList.fulfilled, (state, action) => {
      state.tagList = action.payload.tags ?? initialState.tagList;
    })
    .addCase(getMarketList.fulfilled, (state, action) => {
      const marketList = action.payload.markets ?? initialState.marketList;
      state.marketList = marketList.filter(market => !!market.language && !!market.market);
    })
    .addCase(getEditorsList.fulfilled, (state, action) => {
      state.editorsList = action.payload.editors ?? initialState.editorsList;
    })
    // Clone Case
    .addCase(cloneContentPost.fulfilled, (state, action) => {
      const contentPost = { ...action.payload.contentPost[0], documents: [] };
      state.contentPostClone = {
        ...contentPost,
        title: `${action.payload.contentPost[0].title} (duplicate)`
      };
    })
    .addCase(cloneNewsPost.fulfilled, (state, action) => {
      state.newsPostClone = {
        ...action.payload.newsPost[0],
        title: `${action.payload.newsPost[0].title} (duplicate)`
      };
    })
    .addCase(cloneMarketingPost.fulfilled, (state, action) => {
      state.marketingPostClone = {
        ...action.payload.marketingPost[0],
        title: `${action.payload.marketingPost[0].title} (duplicate)`
      };
    })
    // Edit Case
    .addCase(editContentPost.fulfilled, (state, action) => {
      state.contentPostEdit = action.payload.contentPost;
    })
    .addCase(editMarketingPost.fulfilled, (state, action) => {
      state.marketingPostEdit = action.payload.marketingPost;
    })
    .addCase(editNewsPost.fulfilled, (state, action) => {
      state.newsPostEdit = action.payload.newsPost;
    })
    // Update Case
    // .addCase(updateMarketingPost.fulfilled, (state, action) => {
    //   state.marketingPostUpdated = action.payload;
    // })
    // .addCase(updateNewsPost.fulfilled, (state, action) => {
    //   state.newsPostUpdated = action.payload;
    // })
    // .addCase(updateContentPost.fulfilled, (state, action) => {
    //   state.contentPostUpdated = action.payload;
    // })
    // Filter Case
    .addCase(filtersearchOption, (state, action) => {
      state.filterClauses = [...state.filterClauses, action.payload];
    })
    .addCase(filterdeleteSearchOption, (state, action) => {
      state.filterClauses = state.filterClauses.filter(clause => clause !== action.payload);
    })
    .addCase(filterclearFilters, state => {
      state.filterClauses = initialState.filterClauses;
    })
    .addCase(clearData, state => {
      state.contentPostEdit = initialState.contentPostEdit;
      state.contentPostSave = initialState.contentPostSave;
      state.newsPostEdit = initialState.newsPostEdit;
      state.newsPostSaveData = initialState.nenewsPostSaveDatawsPostEdit;
      state.marketingPostEdit = initialState.marketingPostEdit;
      state.marketingPostSave = initialState.marketingPostSave;
    })
    .addDefaultCase((state, action) => {});
});

export default editorReducer;

/** @format */

import { Box } from '@mui/system';
import { useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import SearchContent from './searchContent';
import { nextPageResult, searchKeyword } from 'src/store/action/search.actions';
import Loader from 'src/components/Loader';

const TabContent = ({ type }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const loading = useSelector(
    state => state.searchReducer[type === 'relevant' ? 'searchLoading' : 'searchLatestLoading']
  );
  const results = useSelector(
    state => state.searchReducer[type === 'relevant' ? 'searchResults' : 'searchLatestResults']
  );
  const nextCursor = useSelector(state => state.searchReducer[type === 'relevant' ? 'nextCursor' : 'nextLatestCursor']);

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextCursor) {
          const payload = {
            keywords: '',
            language: [{ name: user ? user.locale : 'en' }],
            lang: user ? user.locale : 'en',
            cursor: nextCursor,
            order: type || 'relevant'
          };
          dispatch(nextPageResult(payload));
        }
      });

      if (post) intObserver.current.observe(post);
    },
    // eslint-disable-next-line
    [nextCursor]
  );
  // Pagination End

  let lastPostRefSet = false;

  const resultsSorted = results
    ? results.slice().sort((a, b) => {
        return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
      })
    : [];

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        resultsSorted &&
        resultsSorted.map((item, i) => {
          const resultCount = resultsSorted.length;

          if (!lastPostRefSet && i > resultCount - 9) {
            lastPostRefSet = true;

            return <SearchContent key={i} searchData={item} ref={lastPostRef} />;
          }

          return <SearchContent key={i} searchData={item} />;
        })
      )}
    </Box>
  );
};
export default TabContent;

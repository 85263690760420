/** @format */

import { Box } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import TextEditor from 'src/components/reachTextEditor';

import { ListIcon, MenuVideo, MessageIcon, PlatformsIcon, TextEditIcon } from 'src/components/Icons';
import { COMPONENT, COMPONENT_ACTION } from 'src/constants';

import ComContent from './comContent';
import ComContact from './comContact';
import ComServiceNumber from './comServiceNumber';
import ComVideo from './comVideo';
import ComDocument from './comDocument';

import { useAutoAnimate } from '@formkit/auto-animate/react';

export default function PageBuilderContent({
  postData,
  fieldValidate,
  editorValidate,
  onChange,
  setDataByName,
  titleError,
  textError
}) {
  const { t } = useTranslation();
  // const [components, setComponents] = useState([]);
  const [animationParent] = useAutoAnimate();
  const mainContentRef = useRef(null);

  const actionComponent = (action, payload) => {
    const { component, type, value } = payload;
    const _components = postData.components ? [...postData.components] : [];
    const index =
      action !== COMPONENT_ACTION.ADD_COMPONENT ? _components.findIndex(com => com.key === component.key) : 999;

    switch (action) {
      case COMPONENT_ACTION.ADD_COMPONENT:
        setDataByName('components', [
          ..._components,
          {
            key: Math.random(),
            component: type,
            value: null
          }
        ]);
        setTimeout(
          () => {
            mainContentRef.current.scrollTop = 1000 * 1000;
          },
          type === 'Content' ? 500 : 300
        );
        break;

      case COMPONENT_ACTION.REMOVE_COMPONENT:
        if (index > -1) {
          _components.splice(index, 1);
          setDataByName('components', _components);
        }
        break;

      case COMPONENT_ACTION.MOVE_UP_COMPONENT:
        if (index > 0) {
          const tempCom = _components[index];
          _components[index] = _components[index - 1];
          _components[index - 1] = tempCom;
          setDataByName('components', _components);
        }
        break;

      case COMPONENT_ACTION.MOVE_DOWN_COMPONENT:
        if (index < _components.length - 1) {
          const tempCom = _components[index];
          _components[index] = _components[index + 1];
          _components[index + 1] = tempCom;
          setDataByName('components', _components);
        }
        break;

      case COMPONENT_ACTION.UPDATE_VALUE_COMPONENT:
        _components[index].value = value;
        setDataByName('components', [..._components]);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Box className="pageBuilder pageBuilder-mainContent" ref={mainContentRef}>
        <Box className="pageBuilder-contentFieldsWrap">
          <Box className="field-label">{t('editorContent.content.title')}*</Box>
          <Box>
            <OutlinedInputFieldsLight
              placeholder={`${t('editorContent.content.title')}*`}
              name="title"
              onChange={onChange}
              defaultValue={postData && postData.title}
              onBlur={fieldValidate}
              error={titleError || undefined}
            />
          </Box>
        </Box>
        <Box className="pageBuilder-contentFieldsWrap">
          <Box className="field-label">{t('editorContent.content.content')}*</Box>
          <Box className="pageBuilder-textEditor">
            <TextEditor
              setEditorData={setDataByName}
              name="text"
              editorClass={'' ? 'text-area_wrapper error' : 'text-area_wrapper'}
              dataValue={postData && postData.text}
              fieldValidate={editorValidate}
              error={textError || undefined}
            />
          </Box>
        </Box>
        <Box className="pageBuilder-mainContent-list-component" ref={animationParent}>
          {postData.components &&
            postData.components.map((com, index) => {
              return (
                <Fragment key={com.key}>
                  {com.component === COMPONENT.CONTENT && (
                    <ComContent
                      onChange={onChange}
                      setDataByName={setDataByName}
                      components={postData.components}
                      actionComponent={actionComponent}
                      index={index}
                      com={com}
                    />
                  )}
                  {com.component === COMPONENT.CONTACT && (
                    <ComContact
                      onChange={onChange}
                      setDataByName={setDataByName}
                      components={postData.components}
                      actionComponent={actionComponent}
                      index={index}
                      com={com}
                    />
                  )}
                  {com.component === COMPONENT.SINO && (
                    <ComServiceNumber
                      onChange={onChange}
                      setDataByName={setDataByName}
                      components={postData.components}
                      actionComponent={actionComponent}
                      index={index}
                      com={com}
                    />
                  )}
                  {com.component === COMPONENT.VIDEO && (
                    <ComVideo
                      onChange={onChange}
                      setDataByName={setDataByName}
                      components={postData.components}
                      actionComponent={actionComponent}
                      index={index}
                      com={com}
                    />
                  )}
                  {com.component === COMPONENT.UPLOAD && (
                    <ComDocument
                      onChange={onChange}
                      setDataByName={setDataByName}
                      components={postData.components}
                      actionComponent={actionComponent}
                      index={index}
                      com={com}
                    />
                  )}
                </Fragment>
              );
            })}
        </Box>
        <Box className="pageBuilder-contentFieldsWrap pageBuilder-contentFieldsWrap-components">
          <Box className="field-label">{t('editorContent.content.addComponentTitle')}</Box>
          <Box className="pageBuilder-componentList">
            <button
              onClick={() =>
                actionComponent(COMPONENT_ACTION.ADD_COMPONENT, { component: null, type: COMPONENT.CONTENT })
              }>
              <span>Content</span>
              <TextEditIcon color="black" />
            </button>
            <button
              onClick={() =>
                actionComponent(COMPONENT_ACTION.ADD_COMPONENT, { component: null, type: COMPONENT.VIDEO })
              }>
              <span>Video</span>
              <MenuVideo color="black" />
            </button>
            <button
              onClick={() =>
                actionComponent(COMPONENT_ACTION.ADD_COMPONENT, { component: null, type: COMPONENT.CONTACT })
              }>
              <span>Contact</span>
              <MessageIcon color="black" />
            </button>
            <button
              onClick={() =>
                actionComponent(COMPONENT_ACTION.ADD_COMPONENT, { component: null, type: COMPONENT.UPLOAD })
              }>
              <span>Document upload</span>
              <ListIcon color="black" />
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

/** @format */

import React, { Fragment, useState } from 'react';
import { Box, Chip, CircularProgress, IconButton } from '@mui/material';
import { CloseSmallIcon } from '../Icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const AutocompleteSuggestion = props => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [clauses, setClauses] = useState(() => {
    const mergeTags = _.union(
      props.tags ? props.tags.map(item => item.name) : [],
      props.tagIds ? props.tagIds.map(item => item.value.name) : [],
      props.newTags ? props.newTags.map(item => item.value) : []
    );
    return mergeTags && mergeTags.length > 0
      ? mergeTags
      : props.tags && props.tags.length > 0
      ? props.tags.map(item => item.name)
      : props.tagIds && props.tagIds.length > 0
      ? props.tagIds.map(item => item.value.name)
      : props.newTags && props.newTags.length
      ? props.newTags.map(item => item.value)
      : [];
  });

  const onAutocompleteChange = (event, value, reason) => {
    if (reason === 'createOption') {
      createValue(event, value);
    } else if (reason === 'selectOption') {
      selectValue(value);
    }
  };

  const onTextFieldChange = event => {
    setValue(event.target.value);
  };

  const onKeyPressChange = event => {
    if (['Enter', 'Tab', ','].includes(event.key)) {
      createValue(event, event.target.value);
    }
  };

  const onInputChange = (event, value, reason) => {
    props.getSuggestions(event, value, reason);
  };

  const createValue = (event, value) => {
    event.preventDefault();
    const clause = value.trim();

    if (clause && !clauses.includes(clause)) {
      setClauses(clauses => [...clauses, clause]);
      setTags('newTags', clause);
    }
    setValue('');
  };

  const handleDelete = clause => () => {
    setClauses(clauses => clauses.filter(item => item !== clause));

    if (props.newTags && props.newTags.length) {
      const filteredNewTags = props.newTags.filter(item => item.value !== clause);

      if (filteredNewTags.length < props.newTags.length) {
        props.setdatabyname('newTags', filteredNewTags);
      }
    }

    if (props.tags && props.tags.length) {
      const filteredTags = props.tags.filter(item => item.name !== clause);

      if (filteredTags.length < props.tags.length) {
        props.setdatabyname('tags', filteredTags);
      }
    }

    if (props.tagIds && props.tagIds.length) {
      const filteredTagIds = props.tagIds.filter(item => item.value.name !== clause);

      if (filteredTagIds.length < props.tagIds.length) {
        props.setdatabyname('tagIds', filteredTagIds);
      }
    }
  };

  const selectValue = value => {
    const clause = value.name.trim();

    if (clause && !clauses.includes(clause)) {
      setClauses(clauses => [...clauses, clause]);
      setTags('tagIds', { value: value });
    }
    setValue('');
  };

  const setTags = (type, item) => {
    if (type === 'newTags') {
      if (props.newTags) {
        props.setdatabyname('newTags', [...props.newTags, { value: value }]);
      } else {
        props.setdatabyname('newTags', [{ value: value }]);
      }
    } else {
      if (props.tagIds) {
        props.setdatabyname('tagIds', [...props.tagIds, item]);
      } else {
        props.setdatabyname('tagIds', [item]);
      }
    }
  };

  return (
    <Box className={'autoComplete-wrapper autocomplete-suggestion search-chip ' + (props.themeColor ?? 'light')}>
      <Autocomplete
        className={'autoComplete ' + (props.themeColor ?? 'light')}
        id="combo-box-demo"
        setdatabyname={props.setDataByName}
        options={props.options}
        loading={props.loading ?? false}
        loadingText={props.loadingText ?? 'Loading...'}
        value={value}
        disablePortal={true}
        clearIcon={false}
        filterOptions={x => x}
        onChange={onAutocompleteChange}
        onInputChange={onInputChange}
        getOptionLabel={option => option.name ?? option ?? ''}
        renderTags={() => null}
        noOptionsText={t('placeholder.noOptions')}
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            value={value ?? ''}
            onKeyPress={onKeyPressChange}
            onChange={onTextFieldChange}
            placeholder={props.placeholder ?? 'Keyword'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {props.loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
          />
        )}
      />

      {clauses.map((clause, i) => (
        <Chip
          key={i}
          label={clause}
          title={clause}
          onDelete={handleDelete(clause)}
          deleteIcon={
            <IconButton>
              <CloseSmallIcon color="#E38702" height="8" width="8" />
            </IconButton>
          }
        />
      ))}
    </Box>
  );
};

export default AutocompleteSuggestion;

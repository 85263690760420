import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CloseButton from "src/components/Buttons/closeButton";
import { DesktopViewIcon, LeftArrow, MobileViewIcon } from "src/components/Icons";
import TabNav from "src/components/Tabs";
import { previewOpen } from "src/store/action/editor.Action";
import DesktopPreview from "./desktopPreview";
import MobilePreview from "./mobilePreview";




const ContentPreview = props => {

    const contentTab = [
        { heading: <MobileViewIcon width='35' height='35' />, content: <MobilePreview postType={props.postType} postData={props.postData} /> },
        { heading: <DesktopViewIcon width='35' height='35' />, content: <DesktopPreview postType={props.postType} postData={props.postData} /> }
    ];

    const dispatch = useDispatch();
    const handleClose = () => dispatch(previewOpen(false));
    const {t} = useTranslation();
    return (
        <Box className="content-preview-wrapper">
            <CloseButton label={t('editorContent.back')} className="close-button dark" startIcon={<LeftArrow />} onClick={handleClose} />
            <Box className="preview-content">
                <h1>{t('editorContent.tabNav.Preview')}</h1>
                <Box className="absolute-tab">
                    <TabNav data={contentTab} centered={true} />
                </Box>
            </Box>
        </Box>
    )
};



export default ContentPreview;
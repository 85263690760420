/** @format */

import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { lazyWithPreload } from 'react-lazy-with-preload';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EditorHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import TabNav from 'src/components/Tabs';
import useAuth from 'src/hooks/useAuth';
import { getContactDepartmentList, getEditorsList, getMarketList, getRolesList } from 'src/store/action/editor.Action';
import { getTopicList } from 'src/store/action/topic.Action';
import TabContent from './tabContent';

const EditorSetup = lazyWithPreload(() => import('./postCreationSteps/editorSetup'));
const EditorContent = lazyWithPreload(() => import('./postCreationSteps/editorContent'));
const EditorSetting = lazyWithPreload(() => import('./postCreationSteps/editorSetting'));
const PreviewLayer = lazyWithPreload(() => import('./previewLayer/previewLayer'));

const Editor = () => {
  const dispatch = useDispatch();
  const topicList = useSelector(state => state.topicReducer.topicList);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const departmentList = useSelector(state => state.editorReducer.departmentList);
  const marketList = useSelector(state => state.editorReducer.marketList);
  const editorsList = useSelector(state => state.editorReducer.editorsList);
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const contentTab = [
    { heading: t('editorContent.tabNav.all'), content: <TabContent type="all" /> },
    { heading: t('editorContent.tabNav.createdByMe'), content: <TabContent type="own" /> }
  ];

  useEffect(() => {
    if (user.isWorkshop) {
      navigate('/topics');
    } else if (!user.isAdmin && !user.isEditor) {
      navigate('/dashboard');
    }

    EditorSetup.preload();
    EditorContent.preload();
    EditorSetting.preload();
    PreviewLayer.preload();

    if (topicList && topicList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getTopicList(payload));
    }
    if (rolesList && rolesList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getRolesList(payload));
    }
    if (departmentList && departmentList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getContactDepartmentList(payload));
    }
    if (marketList && marketList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getMarketList(payload));
    }
    if (editorsList && editorsList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getEditorsList(payload));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <EditorHeader text={t('Content')} />
      <ScrollAble marginTop={isMobile ? '8px' : '34px'} height={`calc(100vh - 150px)`}>
        <TabNav data={contentTab} />
      </ScrollAble>
    </Fragment>
  );
};

export default Editor;

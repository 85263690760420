/** @format */

import React, { Fragment, lazy, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { marketingPostById } from 'src/store/action/marketing.action';
import { newsById } from 'src/store/action/news.Action';
import { contentPostById } from 'src/store/action/content.action';
import {
  clearData,
  editorOpen,
  editorStepAction,
  previewOpen,
  saveContentPost,
  saveMarketingPost,
  saveNewsArticle,
  savePageBuilderPost,
  updateContentPost,
  updateMarketingPost,
  updateNewsPost
} from 'src/store/action/editor.Action';
import { getContentList } from 'src/store/action/topic.Action';
import LayerHeader from './layerHeader';
import { css, cx } from '@emotion/css';
import useAuth from 'src/hooks/useAuth';
import { postDetailLayerAction, postDetailLayerData, postDetailLayerWidth } from 'src/store/action/detailsLayerAction';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VALIDATE_REGEX } from 'src/constants';
import EditorDistribution from './editorDistrubution';

const EditorSetup = lazy(() => import('./editorSetup'));
const EditorContent = lazy(() => import('./editorContent'));
const EditorSettings = lazy(() => import('./editorSetting'));
const PreviewLayer = lazy(() => import('../previewLayer/previewLayer'));

const PostSelectionLayer = () => {
  const navigate = useNavigate();
  const activeStep = useSelector(state => state.editorReducer.activeStep);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [postType, setPostType] = useState('');
  const [postData, setPostData] = useState(null);
  const pageBuilderPostSave = useSelector(state => state.editorReducer.pageBuilderPostSave);
  const marketingPostSave = useSelector(state => state.editorReducer.marketingPostSave);
  const contentPostSave = useSelector(state => state.editorReducer.contentPostSave);
  const newsPostSaveData = useSelector(state => state.editorReducer.newsPostSaveData);
  const openState = useSelector(state => state.editorReducer.openState);
  const contentPostEdit = useSelector(state => state.editorReducer.contentPostEdit);
  const marketingPostEdit = useSelector(state => state.editorReducer.marketingPostEdit);
  const newsPostEdit = useSelector(state => state.editorReducer.newsPostEdit);
  const contentPostClone = useSelector(state => state.editorReducer.contentPostClone);
  const marketingPostClone = useSelector(state => state.editorReducer.marketingPostClone);
  const newsPostClone = useSelector(state => state.editorReducer.newsPostClone);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const departmentList = useSelector(state => state.editorReducer.departmentList);
  const [clonePost, setClonePost] = useState(false);
  const [editPost, setEditPost] = useState(false);
  const [postPublish, setPostPublish] = useState(false);
  const [errMsg, setErrorMsg] = useState(false);
  const [disabledNext, setDisabledNext] = useState(true);
  const [disabledPublish, setDisabledPublish] = useState(true);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [publishedPostId, setPublishedPostId] = useState(undefined);
  const { t } = useTranslation();
  const filterOptions = useSelector(state => state.topicReducer.filterOptions);

  const customCSS = {
    bottomButtonsWrap: css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 35px 56px;
      z-index: 9;
      @media screen and (max-width: 899px) {
        padding: 35px 20px;
        justify-content: space-between;
      }
    `,
    mandatoryText: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.4);
      margin-right: 40px;
      @media screen and (max-width: 386px) {
        margin-right: 10px;
      }
    `,
    bottomButtons: css`
      @media screen and (max-width: 575px) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        @media screen and (max-width: 386px) {
          button:nth-of-type(2) {
            margin-left: 4px;
          }
        }
      }
    `,
    publishedContent: css`
      background: rgba(250, 244, 235, 0.85);
      backdrop-filter: blur(100px);
      position: absolute;
      inset: 0;
      z-index: 1;
      display: grid;
      place-content: center;
      text-align: center;
    `,
    overviewBtn: css`
      color: #000000 !important;
    `
  };

  useEffect(() => {
    if (newsPostSaveData) {
      dispatch(editorStepAction(3));
      setPostPublish(true);
    }
    if (contentPostSave) {
      dispatch(editorStepAction(3));
      setPostPublish(true);
    }
    if (marketingPostSave) {
      dispatch(editorStepAction(3));
      setPostPublish(true);
    }
    if (pageBuilderPostSave) {
      dispatch(editorStepAction(3));
      setPostPublish(true);
    }
    // eslint-disable-next-line
  }, [marketingPostSave, newsPostSaveData, contentPostSave, pageBuilderPostSave]);

  // Edit Post
  useEffect(() => {
    if (contentPostEdit) {
      let editPostData = contentPostEdit[0];
      setPostData({
        ...editPostData,
        activateNotifications: false,
        roles: prepareRoles(editPostData.roles)
      });
      setEditPost(true);
      dispatch(editorStepAction(1));
      setPostType('content');
    }
    // eslint-disable-next-line
  }, [contentPostEdit]);

  useEffect(() => {
    if (newsPostEdit) {
      let editPostData = newsPostEdit[0];
      let finalDepartment;

      for (let i = 0; i < editPostData.departments.length; i++) {
        finalDepartment = departmentList.find(oneData => {
          return oneData.name.includes(editPostData.departments[i].name);
        });
      }

      setPostData({
        ...editPostData,
        roles: prepareRoles(editPostData.roles),
        departmentIds: finalDepartment
      });
      setEditPost(true);
      dispatch(editorStepAction(1));
      setPostType('news');
    }
    // eslint-disable-next-line
  }, [newsPostEdit]);

  useEffect(() => {
    if (marketingPostEdit) {
      let editPostData = marketingPostEdit[0];
      setPostData({
        ...editPostData,
        roles: prepareRoles(editPostData.roles)
      });
      setEditPost(true);
      dispatch(editorStepAction(1));
      setPostType('marketing');
    }
    // eslint-disable-next-line
  }, [marketingPostEdit]);

  //   Clone posts
  useEffect(() => {
    if (contentPostClone) {
      setPostData({
        ...contentPostClone,
        roles: prepareRoles(contentPostClone.roles)
      });
      setClonePost(true);
      dispatch(editorStepAction(1));
      setPostType('content');
    }
    // eslint-disable-next-line
  }, [contentPostClone]);
  useEffect(() => {
    if (newsPostClone) {
      let finalDepartment;
      for (let i = 0; i < newsPostClone.departments.length; i++) {
        finalDepartment = departmentList.find(oneData => {
          return oneData.name.includes(newsPostClone.departments[i].name);
        });
      }
      setPostData({
        ...newsPostClone,
        roles: prepareRoles(newsPostClone.roles),
        departmentIds: finalDepartment
      });
      setClonePost(true);
      dispatch(editorStepAction(1));
      setPostType('news');
    }
    // eslint-disable-next-line
  }, [newsPostClone]);
  useEffect(() => {
    if (marketingPostClone) {
      setPostData({
        ...marketingPostClone,
        roles: prepareRoles(marketingPostClone.roles)
      });
      setClonePost(true);
      dispatch(editorStepAction(1));
      setPostType('marketing');
    }
    // eslint-disable-next-line
  }, [marketingPostClone]);

  useEffect(() => {
    if (postData && postType) {
      fieldValidation();
    }
    // eslint-disable-next-line
  }, [postData, postType, activeStep]);

  const prepareRoles = roles => {
    let preparedRoles = [];
    for (let i = 0; i < roles.length; i++) {
      let role = rolesList.find(oneData => {
        return oneData.name.includes(roles[i]);
      });

      if (typeof role === 'undefined') continue;

      preparedRoles.push(role);
    }

    return preparedRoles;
  };

  const handleNext = postType => {
    if (activeStep === 0) {
      fieldValidation();
    }

    let actionStep = activeStep + 1;
    dispatch(editorStepAction(actionStep));
    if (postType && postType.id) {
      setPostType(postType.id);
    }
  };

  const handleBack = () => {
    let actionStep = activeStep - 1;
    if (actionStep === 0) {
      setPostType('');
      setPostData(null);
      setDisabledNext(true);
      setDisabledPublish(true);
    }
    dispatch(editorStepAction(actionStep));
  };

  const convertImageUrlToBinary = url => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64Image = reader.result;
          const binaryString = window.atob(base64Image.split(',')[1]);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          resolve(bytes.buffer);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function () {
        reject(new Error('Failed to load image.'));
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const getCreateArticleFormData = async data => {
    const formData = new FormData();

    if (postType === 'marketing') {
      for (const key in data) {
        if (data[key] === '' || data[key] === undefined || data[key] === null) {
          continue;
        }

        if (Array.isArray(data[key])) {
          data[key].forEach(ele => {
            if (ele === undefined || ele === null) {
              return;
            }

            if (key === 'newTags') {
              formData.append(`marketingPost[0][${key}][]`, ele.value);
            } else if (key === 'roles') {
              formData.append(`marketingPost[0][${key}][]`, `${ele.id}`);
            } else if (key === 'topics') {
              formData.append(`marketingPost[0][topicIds][]`, `${ele.id}`);
            } else if (key === 'tags') {
              formData.append(`marketingPost[0][tagIds][]`, `${ele.id}`);
            } else if (key === 'topicIds') {
              formData.append(`marketingPost[0][${key}][]`, ele.value ? `${ele.value}` : `${ele}`);
            } else if (key === 'tagIds') {
              formData.append(`marketingPost[0][${key}][]`, ele.value ? `${ele.value.id}` : `${ele}`);
            }
          });
        } else {
          if (key === 'picture') {
            if (editPost && data[key].id) {
              formData.append(`marketingPost[0][picture][id]`, data[key].id);
              formData.append(`marketingPost[0][picture][remove]`, 'false');
            } else if (clonePost && data[key].id) {
              const imageFile = await convertImageUrlToBinary(data[key].url);
              const filename = data[key].url.replace(/^.*[\\\/]/, '');
              formData.append(
                `marketingPost[0][picture][file]`,
                new Blob([imageFile], { type: 'octet/stream', name: filename })
              );
            } else {
              formData.append(`marketingPost[0][picture][file]`, data[key]);
            }
          } else if (key === 'deletedPictureId') {
            formData.append(`marketingPost[0][picture][id]`, data[key]);
            formData.append(`marketingPost[0][picture][remove]`, 'true');
          } else {
            formData.append(`marketingPost[0][${key}]`, data[key]);
          }
        }
      }
    } else if (postType === 'news') {
      for (const key in data) {
        if (data[key] === '' || data[key] === undefined || data[key] === null) {
          continue;
        }

        if (Array.isArray(data[key])) {
          data[key].forEach(ele => {
            if (ele === undefined || ele === null) {
              return;
            }

            if (key === 'newTags') {
              formData.append(`newsPost[0][${key}][]`, ele.value);
            } else if (key === 'roles') {
              formData.append(`newsPost[0][${key}][]`, `${ele.id}`);
            } else if (key === 'topics') {
              formData.append(`newsPost[0][topicIds][]`, `${ele.id}`);
            } else if (key === 'tags') {
              formData.append(`newsPost[0][tagIds][]`, `${ele.id}`);
            } else if (key === 'topicIds') {
              formData.append(`newsPost[0][${key}][]`, ele.value ? `${ele.value}` : `${ele}`);
            } else if (key === 'tagIds') {
              formData.append(`newsPost[0][${key}][]`, ele.value ? `${ele.value.id}` : `${ele}`);
            }
          });
        } else {
          if (key === 'departmentIds') {
            formData.append(`newsPost[0][${key}][]`, data[key].id);
          } else if (key === 'picture') {
            if (editPost && data[key].id) {
              formData.append(`newsPost[0][picture][id]`, data[key].id);
              formData.append(`newsPost[0][picture][remove]`, 'false');
            } else {
              formData.append(`newsPost[0][picture][file]`, data[key]);
            }
          } else if (key === 'deletedPictureId') {
            formData.append(`newsPost[0][picture][id]`, data[key]);
            formData.append(`newsPost[0][picture][remove]`, 'true');
          } else {
            formData.append(`newsPost[0][${key}]`, data[key]);
          }
        }
      }
    } else if (postType === 'content') {
      for (const key in data) {
        if (data[key] === '' || data[key] === undefined || data[key] === null) {
          continue;
        }

        if (Array.isArray(data[key])) {
          data[key].forEach(async (ele, index) => {
            if (ele === undefined || ele === null) {
              return;
            }

            if (key === 'newTags') {
              formData.append(`contentPost[0][${key}][]`, ele.value);
            } else if (key === 'roles') {
              formData.append(`contentPost[0][${key}][]`, `${ele.id}`);
            } else if (key === 'topics' && !data.hasOwnProperty('topicIds')) {
              formData.append(`contentPost[0][topicIds][]`, `${ele.id}`);
            } else if (key === 'tags') {
              formData.append(`contentPost[0][tagIds][]`, `${ele.id}`);
            } else if (key === 'topicIds') {
              formData.append(`contentPost[0][${key}][]`, ele.value ? `${ele.value}` : `${ele}`);
            } else if (key === 'documents') {
              if (editPost && ele.id) {
                formData.append(`contentPost[0][documents][${index}][id]`, ele.id);
                formData.append(`contentPost[0][documents][${index}][remove]`, 'false');
              } else if (clonePost && data[key].id) {
                const imageFile = await convertImageUrlToBinary(data[key].url);
                const filename = data[key].url.replace(/^.*[\\\/]/, '');
                formData.append(
                  `marketingPost[0][documents][file]`,
                  new Blob([imageFile], { type: 'octet/stream', name: filename })
                );
              } else {
                formData.append(`contentPost[0][documents][${index}][file]`, ele);
              }
            } else if (key === 'deletedDocuments') {
              formData.append(`contentPost[0][documents][${index}][id]`, ele);
              formData.append(`contentPost[0][documents][${index}][remove]`, 'true');
            } else if (key === 'tagIds') {
              formData.append(`contentPost[0][${key}][]`, ele.value ? `${ele.value.id}` : `${ele}`);
            } else if (key === 'casaLinkActive') {
              formData.append(`contentPost[0][${key}]`, ele.value ? ele.value : false);
            } else if (key === 'casaPartNumbers') {
              formData.append(`contentPost[0][${key}][]`, ele);
            } else if (key === 'casaHierarchies') {
              formData.append(`contentPost[0][${key}][]`, ele);
            }
          });
        } else if (key === 'infoType') {
          formData.append(`contentPost[0][infoTypeId]`, data[key]?.id);
        } else {
          formData.append(`contentPost[0][${key}]`, data[key]);
        }
      }
    } else if (postType === 'pageBuilder') {
      for (const key in data) {
        if (data[key] === '' || data[key] === undefined || data[key] === null) {
          continue;
        }

        if (Array.isArray(data[key])) {
          data[key].forEach(async (ele, index) => {
            if (ele === undefined || ele === null) {
              return;
            }

            if (key === 'newTags') {
              formData.append(`contentPost[0][${key}][]`, ele.value);
            } else if (key === 'roles') {
              formData.append(`contentPost[0][${key}][]`, `${ele.id}`);
            } else if (key === 'topics' && !data.hasOwnProperty('topicIds')) {
              formData.append(`contentPost[0][topicIds][]`, `${ele.id}`);
            } else if (key === 'tags') {
              formData.append(`contentPost[0][tagIds][]`, `${ele.id}`);
            } else if (key === 'topicIds') {
              formData.append(`contentPost[0][${key}][]`, ele.value ? `${ele.value}` : `${ele}`);
            } else if (key === 'documents') {
              if (editPost && ele.id) {
                formData.append(`contentPost[0][documents][${index}][id]`, ele.id);
                formData.append(`contentPost[0][documents][${index}][remove]`, 'false');
              } else if (clonePost && data[key].id) {
                const imageFile = await convertImageUrlToBinary(data[key].url);
                const filename = data[key].url.replace(/^.*[\\\/]/, '');
                formData.append(
                  `marketingPost[0][documents][file]`,
                  new Blob([imageFile], { type: 'octet/stream', name: filename })
                );
              } else {
                formData.append(`contentPost[0][documents][${index}][file]`, ele);
              }
            } else if (key === 'deletedDocuments') {
              formData.append(`contentPost[0][documents][${index}][id]`, ele);
              formData.append(`contentPost[0][documents][${index}][remove]`, 'true');
            } else if (key === 'tagIds') {
              formData.append(`contentPost[0][${key}][]`, ele.value ? `${ele.value.id}` : `${ele}`);
            } else if (key === 'casaLinkActive') {
              formData.append(`contentPost[0][${key}]`, ele.value ? ele.value : false);
            } else if (key === 'casaPartNumbers') {
              formData.append(`contentPost[0][${key}][]`, ele);
            } else if (key === 'casaHierarchies') {
              formData.append(`contentPost[0][${key}][]`, ele);
            }
          });
        } else if (key === 'infoType') {
          formData.append(`contentPost[0][infoTypeId]`, data[key]?.id);
        } else {
          formData.append(`contentPost[0][${key}]`, data[key]);
        }
      }
    }

    return formData;
  };

  const publishMarketingPost = async _postData => {
    let payload = await getCreateArticleFormData(_postData);
    dispatch(saveMarketingPost(payload)).then(res => {
      const { status } = res.payload;
      if (status && status !== 201) {
        setErrorMsg(true);
      } else {
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: res.payload.id, type: 'marketing' });
      }
    });
  };
  const publishNewsPost = async _postData => {
    let payload = await getCreateArticleFormData(_postData);
    dispatch(saveNewsArticle(payload)).then(res => {
      const { status } = res.payload;
      if (status && status !== 201) {
        setErrorMsg(true);
      } else {
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: res.payload.id, type: 'news' });
      }
    });
  };
  const publishContentPost = async _postData => {
    let payload = await getCreateArticleFormData(_postData);
    dispatch(saveContentPost(payload)).then(res => {
      const { status } = res.payload;
      if (status && status !== 201) {
        setErrorMsg(true);
      } else {
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: res.payload.id, type: 'content' });
      }
    });
  };
  const publishPageBuilderPost = async _postData => {
    let payload = await getCreateArticleFormData(_postData);
    console.warn('!!!TODO: Need to intergrate with BE');
    dispatch(savePageBuilderPost(payload)).then(res => {
      dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
      dispatch(getContentList(filterOptions));
      setPublishedPostId({ id: 999999, type: 'pageBuilder' });
    });
  };
  const handleFinish = async () => {
    // TODO: Need to continue implements
    if (postType) {
      if (postType === 'marketing') await publishMarketingPost(postData);
      else if (postType === 'news') await publishNewsPost(postData);
      else if (postType === 'content') await publishContentPost(postData);
      else if (postType === 'pageBuilder') await publishPageBuilderPost(postData);
    }
  };

  const updateContent = async _postData => {
    const { id } = _postData;
    let payload = await getCreateArticleFormData(_postData);
    let finalPayload = {
      id,
      payload
    };
    dispatch(updateContentPost(finalPayload)).then(response => {
      const { status } = response.payload;
      if (status === 204) {
        dispatch(editorStepAction(3));
        setPostPublish(true);
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: id, type: 'content' });
      } else {
        setPostPublish(true);
        setErrorMsg(true);
      }
    });
  };
  const updateNews = async _postData => {
    const { id } = _postData;
    let payload = await getCreateArticleFormData(_postData);
    let finalPayload = {
      id,
      payload
    };
    dispatch(updateNewsPost(finalPayload)).then(response => {
      const { status } = response.payload;
      if (status === 204) {
        dispatch(editorStepAction(3));
        setPostPublish(true);
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: id, type: 'news' });
      } else {
        setPostPublish(true);
        setErrorMsg(true);
      }
    });
  };
  const updateMarketing = async _postData => {
    const { id } = _postData;
    let payload = await getCreateArticleFormData(_postData);
    let finalPayload = {
      id,
      payload
    };
    dispatch(updateMarketingPost(finalPayload)).then(response => {
      const { status } = response.payload;
      if (status === 204) {
        dispatch(editorStepAction(3));
        setPostPublish(true);
        dispatch(getContentList(filterOptions));
        dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
        setPublishedPostId({ id: id, type: 'marketing' });
      } else {
        setPostPublish(true);
        setErrorMsg(true);
      }
    });
  };
  const handleUpdate = async () => {
    if (postType) {
      if (postType === 'marketing') await updateMarketing(postData);
      else if (postType === 'news') await updateNews(postData);
      else if (postType === 'content') await updateContent(postData);
    }
  };

  const handleClone = async () => {
    setLoadingPublish(true);
    if (postType && postType === 'marketing') {
      delete postData.id;
      delete postData.published;
      let payload = await getCreateArticleFormData(postData);
      dispatch(saveMarketingPost(payload)).then(res => {
        const { status } = res.payload;
        if (status && status !== 201) {
          setErrorMsg(true);
          setLoadingPublish(false);
        } else {
          setPublishedPostId({ id: res.payload.id, type: 'marketing' });
          setTimeout(() => {
            dispatch(getContentList(filterOptions));
            dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
            setLoadingPublish(false);
          }, 100);
        }
      });
    } else if (postType && postType === 'news') {
      delete postData.id;
      delete postData.published;
      let payload = await getCreateArticleFormData(postData);
      dispatch(saveNewsArticle(payload)).then(res => {
        const { status } = res.payload;
        if (status && status !== 201) {
          setErrorMsg(true);
          setLoadingPublish(false);
        } else {
          setPublishedPostId({ id: res.payload.id, type: 'news' });
          setTimeout(() => {
            dispatch(getContentList(filterOptions));
            dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
            setLoadingPublish(false);
          }, 100);
        }
      });
    } else if (postType && postType === 'content') {
      delete postData.id;
      delete postData.published;
      let payload = await getCreateArticleFormData(postData);
      dispatch(saveContentPost(payload)).then(res => {
        const { status } = res.payload;
        if (status && status !== 201) {
          setErrorMsg(true);
          setLoadingPublish(false);
        } else {
          setPublishedPostId({ id: res.payload.id, type: 'content' });
          setTimeout(() => {
            dispatch(getContentList(filterOptions));
            dispatch(getContentList({ ...filterOptions, onlyOwn: true }));
            setLoadingPublish(false);
          }, 100);
        }
      });
    }
  };

  const togglePreview = () => {
    dispatch(previewOpen(true));
  };

  const setData = e => {
    const { name, value } = e.target;
    setPostData(stats => ({
      ...stats,
      [name]: value
    }));
  };

  const setNotification = event => {
    const { name, checked } = event.target;
    setPostData(stats => ({
      ...stats,
      [name]: checked
    }));
  };

  const setDataByName = (name, data) => {
    if (name === 'topicIds') {
      let topicIds = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i]._children && data[i]._children.length) {
          for (let j = 0; j < data[i]._children.length; j++) {
            topicIds.push({ value: data[i]._children[j] });
          }
          topicIds.push({ value: data[i].id });
        } else {
          topicIds.push({ value: data[i].id });
        }
      }
      setPostData(stats => ({
        ...stats,
        [name]: topicIds
      }));
    } else {
      setPostData(stats => ({
        ...stats,
        [name]: data
      }));
    }
  };

  const setDataValue = (name, data) => {
    if (name === 'locale') {
      setPostData(stats => ({
        ...stats,
        [name]: data.locale
      }));
    } else {
      setPostData(state => ({
        ...state,
        [name]: data.name
      }));
    }
  };

  const setRoles = (name, data) => {
    let roles = [];
    for (let i = 0; i < data.length; i++) {
      roles.push(data[i].name);
    }
    setPostData(stats => ({
      ...stats,
      [name]: roles
    }));
  };

  const resetEditor = () => {
    setPostData(null);
    setPostPublish(false);
    setErrorMsg(false);
    setEditPost(false);
    setPostType('');
    dispatch(clearData());
    dispatch(editorStepAction(0));
  };

  const closeEditor = () => {
    resetEditor();
    dispatch(editorOpen(!openState));
  };

  const openPostDetailLayer = post => {
    const head_payload = {
      title: post.title,
      description: post.description
    };
    closeEditor();
    navigate('/dashboard');
    dispatch(postDetailLayerAction(true));
    dispatch(postDetailLayerWidth(91));
    dispatch(postDetailLayerData(head_payload, post));
  };

  const fieldValidation = (step = null) => {
    if (!postData) {
      setDisabledNext(false);
      return;
    }

    const {
      title,
      text,
      linkTitle,
      link,
      picture,
      departmentIds,
      tags,
      tagIds,
      newTags,
      roles,
      releaseDate,
      expiryDate,
      infoType,
      topics,
      topicIds,
      documents,
      casaLinkActive,
      casaPartNumbers,
      casaHierarchies,
      components,
      allMarkets,
      markets,
      targetGroups,
      targetGroupSelection
    } = postData;
    const currentStep = step ? step : activeStep;

    if (currentStep === 0) {
      if (!postType) {
        setDisabledNext(false);
      } else {
        setDisabledNext(true);
      }
    }

    const step2CommonValid =
      (tagIds?.length || newTags?.length || tags?.length) && (topicIds?.length || topics?.length);
    const step3CommonValid =
      releaseDate && (allMarkets || markets?.length > 0) && targetGroupSelection && targetGroups?.length > 0;

    if (postType === 'marketing') {
      if (currentStep === 1) {
        if (title && text && linkTitle && link && picture && !Array.isArray(picture)) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 2) {
        if (step2CommonValid) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 3) {
        if (step3CommonValid && expiryDate) {
          setDisabledPublish(false);
        } else {
          setDisabledPublish(true);
        }
      }
    } else if (postType === 'news') {
      if (currentStep === 1) {
        if (title && text && departmentIds && picture && !Array.isArray(picture)) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 2) {
        if (step2CommonValid) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 3) {
        if (step3CommonValid) {
          setDisabledPublish(false);
        } else {
          setDisabledPublish(true);
        }
      }
    } else if (postType === 'content') {
      if (currentStep === 1) {
        if (title && text && documents && documents.length > 0 && documents.length <= 5) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 2) {
        if (step2CommonValid && infoType) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 3) {
        if (
          !casaLinkActive ||
          (casaLinkActive &&
            ((casaPartNumbers && casaPartNumbers.length > 0) || (casaHierarchies && casaHierarchies.length > 0)))
        ) {
          setDisabledPublish(false);
        } else {
          setDisabledPublish(true);
        }
      }
    } else if (postType === 'pageBuilder') {
      setDisabledNext(true);
      if (currentStep === 1) {
        if (!components || components.length === 0) {
          if (title && text) {
            setDisabledNext(false);
          }
        } else {
          const validateContact = components
            .filter(com => com.component === 'Contact')
            .every(item => {
              if (!item.value) return false;
              const { name, position, phoneNumber, emailAddress } = item.value;
              if (
                !name ||
                !position ||
                !phoneNumber ||
                !VALIDATE_REGEX.phone.test(phoneNumber) ||
                !emailAddress ||
                !VALIDATE_REGEX.email.test(emailAddress)
              ) {
                return false;
              }
              return true;
            });
          const validateContent = components.filter(com => com.component === 'Content').every(item => !!item.value);
          const validateVideo = components
            .filter(com => com.component === 'Video')
            .every(item => {
              if (!item.value) return false;
              const { title, description, video } = item.value;
              if (!title || !description || !video || video.length === 0) {
                return false;
              }
              return true;
            });
          const validateDocument = components
            .filter(com => com.component === 'Document Upload')
            .every(item => {
              if (!item.value) return false;
              const { title, description, files } = item.value;
              if (!title || !description || !files || files.length === 0) {
                return false;
              }
              return true;
            });
          setDisabledNext(
            !validateContact || !validateContent || !validateVideo || !validateDocument || !title || !text
          );
        }
      } else if (currentStep === 2) {
        if (step2CommonValid && infoType) {
          setDisabledNext(false);
        } else {
          setDisabledNext(true);
        }
      } else if (currentStep === 3) {
        if (
          (!casaLinkActive ||
            (casaLinkActive &&
              ((casaPartNumbers && casaPartNumbers.length > 0) || (casaHierarchies && casaHierarchies.length > 0)))) &&
          step3CommonValid
        ) {
          setDisabledPublish(false);
        } else {
          setDisabledPublish(true);
        }
      }
    }
  };

  const showPublishedContent = () => {
    if (publishedPostId) {
      if (publishedPostId.type === 'marketing') {
        dispatch(marketingPostById({ lang: user ? user.locale : 'en', id: publishedPostId.id })).then(response => {
          if (response.payload?.marketingPost) openPostDetailLayer(response.payload.marketingPost);
        });
      } else if (publishedPostId.type === 'news') {
        dispatch(newsById({ lang: user ? user.locale : 'en', id: publishedPostId.id })).then(response => {
          const { newsPost } = response.payload;
          openPostDetailLayer(newsPost);
        });
      } else if (publishedPostId.type === 'content') {
        dispatch(contentPostById({ contentLang: contentPostEdit?.locale || 'en', id: publishedPostId.id }))
          .then(response => {
            const { contentPost } = response.payload;
            openPostDetailLayer(contentPost);
          })
          .catch(err => {
            // console.log('The content is not found');
          });
      } else if (publishedPostId.type === 'pageBuilder') {
        openPostDetailLayer({
          ...postData,
          postDate: new Date(),
          releaseDate: new Date()
        });
      }
    }
  };

  return (
    <Box className={(activeStep === 0 ? 'editor-setup-page' : 'editor-other-page') + ' steps-wrapper'}>
      <LayerHeader postType={postType} activeStep={activeStep} togglePreview={togglePreview} />
      <PreviewLayer postType={postType} postData={postData} />
      <Fragment>
        {activeStep === 0 && (
          <Box>
            <EditorSetup
              postData={postData}
              handleNext={handleNext}
              setDataValue={setDataValue}
              resetEditor={resetEditor}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <EditorContent
              postType={postType}
              setData={setData}
              setDataByName={setDataByName}
              postData={postData}
              editPost={editPost}
              clonePost={clonePost}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box>
            <EditorSettings
              postType={postType}
              setData={setData}
              setDataByName={setDataByName}
              setNotification={setNotification}
              postData={postData}
              setRoles={setRoles}
              editPost={editPost}
            />
          </Box>
        )}
        {activeStep === 3 && (
          <Box>
            <EditorDistribution
              postType={postType}
              setData={setData}
              setDataByName={setDataByName}
              setNotification={setNotification}
              postData={postData}
              setRoles={setRoles}
              editPost={editPost}
            />
          </Box>
        )}

        {activeStep !== 0 && (
          <Box className={cx(customCSS.bottomButtonsWrap)}>
            <Box className={cx(customCSS.mandatoryText)}>* {t('editorContent.mandatory')}</Box>
            <Box className={cx(customCSS.bottomButtons)}>
              {/* Back/Cancel button */}
              {contentPostEdit ||
              newsPostEdit ||
              marketingPostEdit ||
              contentPostClone ||
              newsPostClone ||
              marketingPostClone ? (
                activeStep >= 2 ? (
                  <IconLabelButtons
                    type="button"
                    label={t('editorContent.back')}
                    className="customButton light"
                    onClick={handleBack}
                    disabled={loadingPublish}
                  />
                ) : (
                  <IconLabelButtons
                    type="button"
                    label={t('editorContent.cancel')}
                    className="customButton light"
                    onClick={closeEditor}
                  />
                )
              ) : activeStep >= 2 ? (
                <IconLabelButtons
                  type="button"
                  label={t('editorContent.back')}
                  className="customButton light"
                  onClick={handleBack}
                />
              ) : (
                <IconLabelButtons
                  type="button"
                  label={t('editorContent.cancel')}
                  className="customButton light"
                  onClick={handleBack}
                />
              )}

              {/* Next/Update/Finish button */}
              {activeStep === 3 ? (
                editPost ? (
                  <IconLabelButtons
                    endIcon={<RightArrow />}
                    type="button"
                    label="Update Post"
                    className="customButton dark"
                    onClick={handleUpdate}
                    sx={{ ml: '16px' }}
                    disabled={disabledPublish}
                  />
                ) : clonePost ? (
                  <IconLabelButtons
                    endIcon={<RightArrow />}
                    type="button"
                    label={loadingPublish ? `Loading` : `Publish`}
                    className="customButton dark"
                    onClick={handleClone}
                    sx={{ ml: '16px' }}
                    disabled={disabledPublish || loadingPublish}
                  />
                ) : (
                  <IconLabelButtons
                    endIcon={<RightArrow />}
                    type="button"
                    label={t('editorContent.publish')}
                    className="customButton dark"
                    onClick={handleFinish}
                    sx={{ ml: '16px' }}
                    disabled={disabledPublish}
                  />
                )
              ) : (
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('editorContent.next')}
                  className="customButton dark"
                  onClick={handleNext}
                  sx={{ ml: '16px' }}
                  disabled={disabledNext || (postData ? postData.errUploadDocument : false)}
                />
              )}
            </Box>
          </Box>
        )}
      </Fragment>
      {postPublish && (
        <Box className={cx(customCSS.publishedContent)}>
          {errMsg ? (
            <Box sx={{ p: '20px' }}>
              <h1 dangerouslySetInnerHTML={{ __html: t('editorContent.editorError') }}></h1>
              <Box
                sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap-reverse', gap: '16px', mt: '70px' }}>
                <IconLabelButtons
                  type="button"
                  label={t('editorContent.return')}
                  className={`customButton ${cx(customCSS.overviewBtn)}`}
                  bgcolor="rgba(0, 0, 0, 0.05) !important"
                  onClick={closeEditor}
                />
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('editorContent.createNew')}
                  className="customButton dark"
                  onClick={resetEditor}
                />
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: '20px' }}>
              <h1 dangerouslySetInnerHTML={{ __html: t('editorContent.editorSuccess') }}></h1>
              <Box
                sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap-reverse', gap: '16px', mt: '70px' }}>
                <IconLabelButtons
                  type="button"
                  label={t('editorContent.return')}
                  className={`customButton ${cx(customCSS.overviewBtn)}`}
                  bgcolor="rgba(0, 0, 0, 0.05) !important"
                  onClick={closeEditor}
                />
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('editorContent.view')}
                  className="customButton dark"
                  onClick={() => showPublishedContent()}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PostSelectionLayer;

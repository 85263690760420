/** @format */

import { css, cx } from '@emotion/css';
import { Divider, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { copyToClipboard } from '@phntms/react-share';
import { format } from 'date-fns';
import { lazy, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import IconLabelButtons from 'src/components/Buttons';
import CloseButton from 'src/components/Buttons/closeButton';
import { CopyIcon, LeftArrow, PrintIcon, ShareIcon, ThreeDotsIcon } from 'src/components/Icons';
import { ScrollAble } from 'src/components/ScrollAble';
import { ChipBasic } from 'src/components/Tags';
import useAuth from 'src/hooks/useAuth';
import { topicDisplayName } from 'src/utils/topicDisplayName';

const MultiplePDFViewer = lazy(() => import('src/widgets/cardLayer/multiplePDFViewer'));

const customCSS = {
  layerCloseButton: css`
    width: 100px;
    z-index: 99;
    &.close-button {
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }
  `,
  heading: css`
    margin-top: 40px;
  `,
  postDetailContainer: css`
    max-width: 1348px;
    margin: 40px auto;

    & > * {
      max-width: 800px;
      margin: 0 auto;

      & hr {
        text-align: center;
      }
    }
  `,
  postDate: css`
    color: #6b6b6b;
    letter-spacing: 0.01em;
  `,
  postHeading: css`
    margin-top: 8px;
  `,
  postTags: css`
    display: flex;
    column-gap: 4px;
    margin-top: 40px;
    flex-wrap: wrap;
    row-gap: 4px;
  `,
  divider: css`
    &.MuiDivider-root {
      background: rgba(0, 0, 0, 0.3);

      margin: 24px auto 0;
    }
  `
};

export default function TopicPageContent({ handleClosePostDetailLayer, postDetail }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const showTags = user?.roles?.includes('Extranet Admin') || user?.roles?.includes('Extranet Editor');
  const [menuOpen, setMenuOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const scrollAbleRef = useRef();
  const urlInputFieldRef = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const isNews = !!postDetail.pictureUrl;
  const shareUrl = `${window.location.origin}${isNews ? `/news/${postDetail.id}` : `/content/${postDetail.id}`}`;

  const handlePrint = useReactToPrint({
    content: () => scrollAbleRef.current,
    pageStyle: {
      padding: 10
    },
    onBeforePrint: () => setMenuOpen(!menuOpen)
  });
  const openSharingBox = () => {
    setShareOpen(!shareOpen);
    setMenuOpen(!menuOpen);
  };
  const handleToCopyUrl = () => {
    copyToClipboard(shareUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };
  return (
    <>
      <CloseButton
        label="Back"
        startclose={+true}
        startIcon={<LeftArrow />}
        onClick={() => handleClosePostDetailLayer()}
        className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
      />
      <Box className="content-actions content-actions-content-page">
        <Box className="content-action-menu-wrap">
          <button onClick={() => setMenuOpen(!menuOpen)} className={menuOpen ? 'active' : ''}>
            <ThreeDotsIcon color="#000000" />
          </button>
          {menuOpen && (
            <Box className="action-menu">
              <button className="action-menu-item" onClick={openSharingBox}>
                <span>
                  <ShareIcon width={18} height={18} />
                </span>{' '}
                Share
              </button>
              <button className="action-menu-item" onClick={handlePrint}>
                <span>
                  <PrintIcon width={28} height={28} />
                </span>{' '}
                Print
              </button>
            </Box>
          )}
        </Box>
      </Box>
      <div ref={scrollAbleRef} style={{ height: 'inherit' }}>
        <ScrollAble marginTop="34px" className="admin-user">
          <Box className={cx(customCSS.postDetailContainer)}>
            <Box className={`p-small ${cx(customCSS.postDate)}`}>
              {postDetail && format(new Date(postDetail.releaseDate), 'PPP')}
            </Box>
            <h2 className={cx(customCSS.postHeading)}>{postDetail && postDetail.title}</h2>
            {showTags && (
              <Box className={cx(customCSS.postTags)}>
                {postDetail &&
                  postDetail.topics &&
                  postDetail.topics.length &&
                  postDetail.topics.map((item, index) => {
                    return <ChipBasic key={index} label={topicDisplayName(item)} background={'rgba(0, 0, 0, 0.05)'} />;
                  })}
                {postDetail &&
                  postDetail.tags &&
                  postDetail.tags.length &&
                  postDetail.tags.map((item, index) => {
                    return <ChipBasic key={index} label={item.name} background={'#F4E7D4'} />;
                  })}
              </Box>
            )}
            <Divider className={cx(customCSS.divider)} />

            <Box
              component="p"
              sx={{ mt: '56px' }}
              dangerouslySetInnerHTML={{ __html: postDetail && postDetail.text }}></Box>

            {postDetail && postDetail.documents && postDetail.documents.length && (
              <MultiplePDFViewer documents={postDetail.documents} scrollAbleRef={scrollAbleRef} />
            )}

            <Divider className={cx(customCSS.divider)} sx={{ mt: '80px !important' }} />
          </Box>
        </ScrollAble>
      </div>
      <Modal
        open={shareOpen}
        onClose={() => setShareOpen(!shareOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal content-shareing-modal">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Share this content
          </Typography>
          <Box className="share-url-text">
            <TextField
              className="share-url-text-input"
              readonly
              ref={urlInputFieldRef}
              value={shareUrl}
              onFocus={e => {
                e.target.select();
                handleToCopyUrl();
              }}
            />
            <Tooltip title={isCopied ? `Copied` : `Copy the link`} placement="top" arrow>
              <button title="Copy the link" onClick={handleToCopyUrl}>
                <CopyIcon width={24} height={24} />
              </button>
            </Tooltip>
          </Box>
          <Box sx={{ mt: 4, justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
            <IconLabelButtons
              type="button"
              label="Close"
              className="customButton dark"
              onClick={() => setShareOpen(!shareOpen)}
            />
          </Box>
        </Box>
      </Modal>
      {menuOpen && (
        <Box
          sx={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', zIndex: '1' }}
          onClick={() => setMenuOpen(!menuOpen)}></Box>
      )}
    </>
  );
}

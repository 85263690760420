/** @format */

import React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { EyeIcon, HideIcon } from '../Icons';

const OutlineInputWithIconsLight = props => {
  const { margin, ...rest } = props;
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <FormControl
      // sx={{ m: 1 }}
      variant="outlined"
      className="text-fields outlined-input-icon light"
      {...props}>
      <OutlinedInput
        // id="outlined-adornment-password"
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange('password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {values.showPassword ? <HideIcon /> : <EyeIcon color="rgb(107,107,107)" />}
              {/* Use the above alert icon to show in case of an error */}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
    </FormControl>
  );
};

export default OutlineInputWithIconsLight;

/** @format */

import { Box, Dialog, DialogContent } from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconLabelButtons from 'src/components/Buttons';
import { DeleteIcon, EditIcon, RightArrow } from 'src/components/Icons';
import { marketMap } from 'src/utils/market';
import UserEditor from './userEditor';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUserList } from 'src/store/action/user.Action';

export const UserItem = props => {
  const { i, user, lastPostRef } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [market, setMarket] = useState(user.locale || 'en');
  const [roles, setRoles] = useState(user.roles || []);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const { userListPayload } = useSelector(state => state.userReducer);
  const [userLoading, setUserLoading] = useState(false);

  const deleteConfirm = () => {
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  };
  const handleCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  };
  const handleDeleteUser = async () => {
    setUserLoading(true);
    await dispatch(deleteUser(user));
    await dispatch(getUserList(userListPayload));
    setUserLoading(false);
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  };

  return (
    <Fragment>
      <Box className="list-item" ref={lastPostRef}>
        <Box className="role">
          <label>{t('admin.role')}</label>
          <span>{roles.join('; ')}</span>
        </Box>
        <Box className="locale">
          <label>{t('admin.locale')}</label>
          <span>{market ? marketMap.get(market) : user.locale ? marketMap.get(user.locale) : 'Global'}</span>
        </Box>
        <Box className="company">
          <label>{t('admin.company')}</label>
          <span>{user.company}</span>
        </Box>
        <Box className="f-name l-name">
          <label>{t('admin.name')}</label>
          <span>
            {user.firstName} {user.lastName}
          </span>
        </Box>
        <Box className="email">
          <label>{t('admin.email')}</label>
          <span>{user.email}</span>
        </Box>
        <Box className="register-date">
          <label>{t('admin.registrations_date')}</label>
          <span>{user.created ? format(new Date(user.created * 1000), 'P') : ''}</span>
        </Box>
        <Box className="action">
          {typeof props.isOpen === 'undefined' && (
            <>
              <label>{t('admin.action')}</label>
              <span className="buttons">
                <IconLabelButtons
                  endIcon={<EditIcon color="#000" />}
                  type="button"
                  className="customButton noLabel light small-tailless"
                  onClick={() => props.setIsOpen(i)}
                />
                <IconLabelButtons
                  endIcon={<DeleteIcon color="#000" />}
                  type="button"
                  className="customButton noLabel small-tailless close"
                  onClick={deleteConfirm}
                />
              </span>
            </>
          )}
        </Box>
      </Box>
      {props.isOpen === i && (
        <UserEditor
          i={i}
          user={user}
          market={market}
          setMarket={setMarket}
          setIsOpen={props.setIsOpen}
          roles={roles}
          setRoles={setRoles}
        />
      )}
      {isOpenDeleteDialog && (
        <Dialog open={true} onClose={handleCloseDeleteDialog} className="delete-dialog">
          <DialogContent>
            <Box className="delete-user-dialog">
              <Box className="delete-confirm">{t('user.deleteDialogTitle')}</Box>
              <Box className="delete-confirm-name">
                {user.firstName} {user.lastName}
              </Box>
              <Box className="delete-confirm-desc">{t('user.deleteDialogContent')}</Box>
              <Box className="delete-confirm-btns">
                <IconLabelButtons
                  type="button"
                  label={t('user.cancel')}
                  className="customButton light"
                  onClick={handleCloseDeleteDialog}
                />
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={t('user.yesDelete')}
                  className="customButton dark"
                  onClick={handleDeleteUser}
                  disabled={userLoading}
                  sx={{ ml: '16px' }}
                />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

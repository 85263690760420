/** @format */

import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import { css, cx } from '@emotion/css';
import 'src/styles/components/editor.scss';
import CustomUploadAdapterPlugin from '../../utils/CustomUploadAdapterPlugin';

const Editor = ({ name, dataValue, textError, setEditorData, fieldValidate }) => {
  const customCSS = {
    error: css`
      .ck.ck-editor__main > .ck-editor__editable {
        --ck-color-base-border: rgba(255, 0, 0, 1);
      }
    `
  };

  const config = {
    extraPlugins: [CustomUploadAdapterPlugin],
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'superscript',
        'subscript',
        '|',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'imageUpload',
        'undo',
        'redo'
      ]
    },
    language: 'en',
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side']
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    link: {
      addTargetToExternalLinks: true
    }
  };

  return (
    <div className={`${cx(textError ? customCSS.error : '')}`}>
      <CKEditor
        editor={ClassicEditor}
        data={dataValue ? dataValue : ''}
        config={config}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(name, data);
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          fieldValidate(data);
        }}
        onFocus={() => {}}
        disabled={false}
      />
    </div>
  );
};

export default Editor;

/** @format */

import { css } from '@emotion/css';
import { Box, Modal, Typography } from '@mui/material';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IconLabelButtons from 'src/components/Buttons';
import { SearchHeader } from 'src/components/PageHeader';
import { ScrollAble } from 'src/components/ScrollAble';
import useAuth from 'src/hooks/useAuth';
import {
  getCandidatesList,
  getUserRoleByLang,
  nextCandidatesList,
  updateCandidate,
  updateUserRole
} from 'src/store/action/admin.Action';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { updateProfile } from 'src/store/action/profile.Action';
import { marketMap } from 'src/utils/market';
import { UserItem } from './UserItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Loader from 'src/components/Loader';
import { getRolesList } from 'src/store/action/editor.Action';

const customCSS = {
  previewLayer: css`
    &.MuiPaper-root {
      background: #fffdfa;
      padding: 40px 60px;
      border-radius: 40px 0 0 40px;
      width: 90%;
      @media screen and (max-width: 768px) {
        padding: 20px 16px;
      }
    }
  `,
  layerCloseButton: css`
    &.close-button {
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }
  `,
  heading: css`
    margin-top: 40px;
  `,
  cardsWrap: css`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  `,
  postHeading: css`
    margin-top: 8px;
  `
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const LabelSorting = ({ label, name, sortBy, setSortBy }) => {
  return (
    <span
      className={`sort-label ${sortBy.sortBy === name ? `sort-label-active` : ``}`}
      onClick={() => {
        if (sortBy.sortBy !== name || sortBy.sort === 'DESC') {
          setSortBy({ sortBy: name, sort: 'ASC' });
        } else {
          setSortBy({ sortBy: name, sort: 'DESC' });
        }
      }}>
      {label}
      {sortBy.sortBy === name && (
        <>
          {sortBy.sort === 'ASC' && <ArrowDropUpIcon />}
          {sortBy.sort === 'DESC' && <ArrowDropDownIcon />}
        </>
      )}
    </span>
  );
};

const AdminCandidates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const roles = useSelector(state => state.editorReducer.rolesList);
  const navigate = useNavigate();
  const [isModalOpen, setDeleteModal] = useState(false);
  const [candidateUuid, setCandidateUuid] = useState(undefined);
  const [sortBy, setSortBy] = useState({ sortBy: 'created', sort: 'ASC' });
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const candidatesList = useSelector(state =>
    state.adminReducer.candidatesList.filter(item => Array.isArray(item.roles) && item.roles?.length > 0)
  );
  const { loading, nextCursor, filter: filterMarket, roles: marketRoles } = useSelector(state => state.adminReducer);

  useEffect(() => {
    if (!user.isAdmin) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!roles || roles.length === 0) {
      dispatch(
        getRolesList({
          lang: user ? user.locale : 'en'
        })
      ).then(() => {
        // Load all roles per each market
        for (let [market] of marketMap) {
          if (!marketRoles[market]) {
            // dispatch(getUserRoleByLang({ lang: market }));
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(navigationOpenAction(false));
    const getCandidates = async () => {
      if (candidatesList && candidatesList.length <= 0) {
        const payload = {
          lang: user ? user.locale : 'en',
          limit: 50
        };
        await dispatch(getCandidatesList(payload));
      }
    };
    getCandidates();
    // eslint-disable-next-line
  }, []);

  const approveUser = ({ user, market, roles }) => {
    const payload = { ...user, roles: roles, locale: market };
    dispatch(updateProfile(payload)).then(() => {
      const payload = {
        uuid: user.uuid,
        roles: roles,
        lang: user ? user.locale : 'en'
      };
      dispatch(updateUserRole(payload)).then(() => {
        const payload = {
          id: user.uuid,
          approve: true,
          lang: user ? user.locale : 'en'
        };
        dispatch(updateCandidate(payload)).then(() => {
          const payload = {
            lang: user ? user.locale : 'en',
            limit: 50
          };
          dispatch(getCandidatesList(payload));
        });
      });
    });
  };

  const rejectUser = (confirm, uuid) => {
    setDeleteModal(!confirm);
    setCandidateUuid(uuid);

    if (confirm) {
      const payload = {
        id: candidateUuid,
        approve: false,
        lang: user ? user.locale : 'en'
      };
      dispatch(updateCandidate(payload)).then(() => {
        const payload = {
          lang: user ? user.locale : 'en',
          limit: 50
        };
        dispatch(getCandidatesList(payload));
      });

      setDeleteModal(!confirm);
      setCandidateUuid(undefined);
    }
  };

  // Pagination Start
  const intObserver = useRef();
  const lastPostRef = useCallback(
    post => {
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver(posts => {
        if (posts[0].isIntersecting && nextCursor) {
          const payload = {
            lang: user ? user.locale : 'en',
            limit: 50,
            cursor: nextCursor
          };
          dispatch(nextCandidatesList(payload));
        }
      });

      if (post) intObserver.current.observe(post);
    },
    // eslint-disable-next-line
    [nextCursor]
  );
  // Pagination End

  const finalCandidatesList = filterMarket.length
    ? candidatesList.filter(item => {
        return !!filterMarket.find(market => market.id === item.locale);
      })
    : candidatesList;

  switch (sortBy.sortBy) {
    case 'role':
      if (sortBy.sort === 'ASC') finalCandidatesList.sort((a, b) => a.roles[0].localeCompare(b.roles[0]));
      if (sortBy.sort === 'DESC') finalCandidatesList.sort((a, b) => b.roles[0].localeCompare(a.roles[0]));
      break;
    case 'locale':
      if (sortBy.sort === 'ASC') {
        finalCandidatesList.sort((a, b) => {
          const localeA = a.locale || 'en';
          const localeB = b.locale || 'en';
          return localeA.localeCompare(localeB);
        });
      }
      if (sortBy.sort === 'DESC') {
        finalCandidatesList.sort((a, b) => {
          const localeA = a.locale || 'en';
          const localeB = b.locale || 'en';
          return localeB.localeCompare(localeA);
        });
      }
      break;
    case 'company':
      if (sortBy.sort === 'ASC') finalCandidatesList.sort((a, b) => a.company.localeCompare(b.company));
      if (sortBy.sort === 'DESC') finalCandidatesList.sort((a, b) => b.company.localeCompare(a.company));
      break;
    case 'firstName':
      if (sortBy.sort === 'ASC') finalCandidatesList.sort((a, b) => a.firstName.localeCompare(b.firstName));
      if (sortBy.sort === 'DESC') finalCandidatesList.sort((a, b) => b.firstName.localeCompare(a.firstName));
      break;
    case 'created':
      if (sortBy.sort === 'ASC') finalCandidatesList.sort((a, b) => a.created - b.created);
      if (sortBy.sort === 'DESC') finalCandidatesList.sort((a, b) => b.created - a.created);
      break;
    case 'email':
      if (sortBy.sort === 'ASC') finalCandidatesList.sort((a, b) => a.email.localeCompare(b.email));
      if (sortBy.sort === 'DESC') finalCandidatesList.sort((a, b) => b.email.localeCompare(a.email));
      break;
    default:
      finalCandidatesList.sort((a, b) => a.created - b.created);
      break;
  }

  return (
    <div className={`${checkNavOpen ? `admin-user-nav-open` : ``}`}>
      <SearchHeader heading={t('admin.heading')} />
      <ScrollAble marginTop="34px" className={`admin-user`}>
        <Box className="user-list">
          {/* head table */}
          <Box className="heading-column">
            <Box className="role">
              <LabelSorting label={t('admin.role')} name="role" sortBy={sortBy} setSortBy={setSortBy} />
            </Box>
            <Box className="locale">
              <LabelSorting label={t('admin.locale')} name="locale" sortBy={sortBy} setSortBy={setSortBy} />
            </Box>
            {!checkNavOpen && (
              <Box className="company">
                <LabelSorting label={t('admin.company')} name="company" sortBy={sortBy} setSortBy={setSortBy} />
              </Box>
            )}
            <Box className="f-name">
              <LabelSorting label={t('admin.name')} name="firstName" sortBy={sortBy} setSortBy={setSortBy} />
            </Box>
            {!checkNavOpen && (
              <Box className="register-date">
                <LabelSorting
                  label={t('admin.registrations_date')}
                  name="created"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Box>
            )}
            {!checkNavOpen && (
              <Box className="email">
                <LabelSorting label={t('admin.email')} name="email" sortBy={sortBy} setSortBy={setSortBy} />
              </Box>
            )}
            <Box className="action">{t('admin.actions')}</Box>
          </Box>
          {/* item list */}
          {loading || roles.length === 0 ? (
            <Loader />
          ) : finalCandidatesList.length > 0 ? (
            finalCandidatesList.map((item, i) => {
              return (
                <UserItem
                  key={item.uuid}
                  i={i}
                  user={item}
                  lastPostRef={lastPostRef}
                  approveUser={approveUser}
                  rejectUser={rejectUser}
                  customCSS={customCSS}
                />
              );
            })
          ) : (
            <Box className="content-no-results">{t('searchContent.noResult')}</Box>
          )}
        </Box>
      </ScrollAble>
      <Modal open={isModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('admin.rejectConfirmation.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('admin.rejectConfirmation.description')}
          </Typography>
          <Box sx={{ mt: 4, justifyContent: 'space-between', display: 'flex' }}>
            <IconLabelButtons
              type="button"
              label={t('admin.rejectConfirmation.no')}
              className="customButton dark"
              onClick={() => setDeleteModal(false)}
            />
            <IconLabelButtons
              type="button"
              label={t('admin.rejectConfirmation.yes')}
              className="customButton dark"
              onClick={() => rejectUser(true)}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminCandidates;

import axiosInstance from './axios';

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class MyUploadAdapter {
  constructor(props) {
    this.loader = props;
    this.controller = new AbortController();
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      const data = new FormData();

      this.loader.file.then((file) => {
        data.append('upload', file);
        axiosInstance.post('/images/upload', data, { signal: this.controller.signal, onUploadProgress: progressEvent => {
          if ( progressEvent.lengthComputable ) {
            this.loader.uploadTotal = progressEvent.total;
            this.loader.uploaded = progressEvent.loaded;
          }
        }})
          .then((response) =>  response.data?.url ? resolve({ default: response.data.url }) : reject(`Couldn't upload file: ${ file.name }.`))
          .catch((error) => reject(error.message ? error.message : `Couldn't upload file: ${ file.name }.`));
      })
    } );
  }

  // Aborts the upload process.
  abort() {
    this.controller.abort()
  }
}

export default CustomUploadAdapterPlugin

/** @format */

import axios from 'src/utils/axios';

export const getTagSuggestions = async search => {
  try {
    const response = await axios.get(`/tags/search?search=${search}`);

    if (!response.data?.tags.length) {
      return [];
    } else {
      return response.data.tags;
    }
  } catch (error) {
    return [];
  }
};

/** @format */

import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LayerHeader from '../postCreationSteps/layerHeader';
import PreviewLayer from '../previewLayer/previewLayer';

import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import PageBuilderContent from './components';

const PageBuilder = props => {
  const { postType, postData, editPost, clonePost } = props;
  const { t } = useTranslation();
  const [editorError, setEditorError] = useState(false);

  const navigate = useNavigate();
  const activeStep = useSelector(state => state.editorReducer.activeStep);
  const dispatch = useDispatch();

  const customCSS = {
    bottomButtonsWrap: css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #faf4eb;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 35px 56px;
      @media screen and (max-width: 899px) {
        padding: 35px 20px;
        justify-content: space-between;
      }
    `,
    mandatoryText: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.4);
      margin-right: 40px;
      @media screen and (max-width: 386px) {
        margin-right: 10px;
      }
    `,
    bottomButtons: css`
      @media screen and (max-width: 575px) {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        @media screen and (max-width: 386px) {
          button:nth-of-type(2) {
            margin-left: 4px;
          }
        }
      }
    `,
    publishedContent: css`
      background: rgba(250, 244, 235, 0.85);
      backdrop-filter: blur(100px);
      position: absolute;
      inset: 0;
      z-index: 1;
      display: grid;
      place-content: center;
      text-align: center;
    `,
    overviewBtn: css`
      color: #000000 !important;
    `
  };

  const fieldValidate = e => {};

  const onChange = e => {
    props.setData(e);
    fieldValidate(e);
  };

  const editorValidate = data => {
    if (data === '') {
      setEditorError(true);
    } else {
      setEditorError(false);
    }
  };

  return (
    <Box className="custom-swipeable">
      <Box className={'editor-other-page' + ' steps-wrapper'}>
        <LayerHeader postType={'pageBuilder'} activeStep={activeStep} togglePreview={() => {}} />
        <PreviewLayer postType={postType} postData={postData} />
        <PageBuilderContent postData={postData} />
        <Box className={cx(customCSS.bottomButtonsWrap)}>
          <Box className={cx(customCSS.mandatoryText)}>* {t('editorContent.mandatory')}</Box>
          <Box className={cx(customCSS.bottomButtons)}>
            {/* CANCEL BUTTON */}
            <IconLabelButtons
              type="button"
              label={t('editorContent.cancel')}
              className="customButton light"
              onClick={() => {}}
            />

            {/* NEXT BUTTON */}
            <IconLabelButtons
              // endIcon={<RightArrow />}
              type="button"
              label={t('editorContent.saveDraft')}
              className="customButton dark"
              onClick={() => {}}
              sx={{ ml: '16px' }}
              disabled={false}
            />
            <IconLabelButtons
              endIcon={<RightArrow />}
              type="button"
              label={t('editorContent.next')}
              className="customButton dark"
              onClick={() => {}}
              sx={{ ml: '16px' }}
              disabled={false}
            />
            {/* PUBLIC BUTTON */}
            {/* <IconLabelButtons
              endIcon={<RightArrow />}
              type="button"
              label={t('editorContent.publish')}
              className="customButton dark"
              onClick={() => {}}
              sx={{ ml: '16px' }}
              disabled={false}
            /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PageBuilder;

/** @format */
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import { ComponentTools } from './comTools';

export default function ComServiceNumber({ components, actionComponent, com }) {
  const { t } = useTranslation();

  return (
    <Box className="pageBuilder-contentFieldsWrap">
      <Box className="field-label">{t('editorContent.content.serviceInformationTitle')}*</Box>
      <Box className="fields-service-number">
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.idNumber')}*`}
          name={`sino-${com.key}`}
          onChange={() => {}}
          defaultValue={com.vallue || ''}
          // onBlur={fieldValidate}
          // error={''}
        />
        <ComponentTools components={components} action={actionComponent} component={com} />
      </Box>
    </Box>
  );
}

/** @format */

import DropdownTreeSelect from 'react-dropdown-tree-select';
import '../../../node_modules/react-dropdown-tree-select/dist/styles.css';

const Container = props => {
  const { selectedClass, value, data, onBlur, ...rest } = props;

  return (
    <DropdownTreeSelect
      data={data}
      className={selectedClass}
      inlineSearchInput={true}
      showPartiallySelected={true}
      texts={props.texts}
      {...rest}
      isDefaultValue={true}
      value={value}
      onBlur={onBlur}
    />
  );
};
export default Container;

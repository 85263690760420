/** @format */

import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { CheckLabelTransparentLight } from 'src/components/Checkbox';
import { CheckIcon, ChevronDown, ChevronUp } from 'src/components/Icons';
import Loader from 'src/components/Loader';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
import RadioBox from 'src/components/Radio';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import useAuth from 'src/hooks/useAuth';
import { getInfoTypeList } from 'src/store/action/editor.Action';
import { clear, getNewsletterSettings, updateNewsletterSettings, updateProfile } from 'src/store/action/profile.Action';
import { getTopicList } from 'src/store/action/topic.Action';

const NewsLetter = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const { t } = useTranslation();
  const { user, getUserInfo } = useAuth();
  const [userData, setUserData] = useState(null);
  const [settings, setSettings] = useState({
    active: false,
    frequency: 'daily',
    infoTypes: [],
    topics: []
  });
  const topicList = useSelector(state => state.topicReducer.topicList);
  const infoTypeList = useSelector(state => state.editorReducer.infoTypeList);
  const { newsletterSettings, updateNewsletterSettingsErrorMessage, updateNewsletterSettingsSuccess } = useSelector(
    state => state.profileReducer
  );
  const [customTopicList, setCustomTopicList] = useState([]);

  useEffect(() => {
    if (topicList.length === 0) {
      dispatch(
        getTopicList({
          lang: user ? user.locale : 'en'
        })
      );
    }

    if (typeof infoTypeList === 'undefined' || infoTypeList.length === 0) {
      dispatch(
        getInfoTypeList({
          lang: user ? user.locale : 'en'
        })
      );
    }
    if (typeof newsletterSettings === 'undefined') {
      dispatch(
        getNewsletterSettings({
          uuid: user.uuid,
          lang: user ? user.locale : 'en'
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) setUserData(user);
  }, [user]);

  useEffect(() => {
    if (newsletterSettings) {
      const _settings = _.cloneDeep(newsletterSettings);
      if (!Array.isArray(_settings.topics)) {
        _settings.topics = Object.values(_settings.topics);
      }
      // Set active all topics and type at the first time
      if (
        !newsletterSettings.active &&
        newsletterSettings.topics.length === 0 &&
        newsletterSettings.infoTypes.length === 0
      ) {
        if (topicList.length) {
          topicList.forEach(lv1 => {
            _settings.topics.push(lv1.id);
            if (lv1.children) {
              lv1.children.forEach(lv2 => {
                _settings.topics.push(lv2.id);
                if (lv2.children) {
                  lv2.children.forEach(lv3 => {
                    _settings.topics.push(lv3.id);
                  });
                }
              });
            }
          });
        }
        if (infoTypeList.length) {
          infoTypeList.forEach(type => {
            _settings.infoTypes.push(type.id);
          });
        }
      }
      setSettings(_settings);
      if (infoTypeList.length > 0) {
        setLoad(true);
      }
    }
    if (topicList.length !== customTopicList.legnth) {
      setCustomTopicList(
        topicList.map(l1 => ({
          ...l1,
          open: false,
          children: l1.children?.map(l2 => ({
            ...l2,
            open: false,
            children: l2.children?.map(l3 => ({ ...l3, open: false }))
          }))
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletterSettings, topicList, infoTypeList]);

  const handleCheckTopic = e => {
    const value = typeof e.target.value === 'string' ? parseInt(e.target.value) : e.target.value;
    const newSettings = _.cloneDeep(settings);
    if (e.target.checked) {
      newSettings.topics.push(value);
    } else {
      newSettings.topics = newSettings.topics.filter(topic => topic !== value);
    }

    // handle children
    let selectedTopic = topicList.find(topic => topic.id === value);
    let parentTopic = null;
    let grandParentTopic = null;
    if (!selectedTopic) {
      for (let index = 0; index < topicList.length; index++) {
        if (topicList[index].children) {
          selectedTopic = topicList[index].children.find(topicLv2 => topicLv2.id === value);
          if (selectedTopic) {
            parentTopic = topicList[index];
            break;
          }
        }
      }
      if (!selectedTopic) {
        for (let index = 0; index < topicList.length; index++) {
          if (topicList[index].children) {
            for (let jndex = 0; jndex < topicList[index].children.length; jndex++) {
              if (topicList[index].children[jndex].children) {
                selectedTopic = topicList[index].children[jndex].children.find(topicLv3 => topicLv3.id === value);
                if (selectedTopic) {
                  parentTopic = topicList[index].children[jndex];
                  break;
                }
              }
            }
            if (selectedTopic) {
              grandParentTopic = topicList[index];
              break;
            }
          }
        }
      }
    }

    if (e.target.checked) {
      if (selectedTopic) {
        if (parentTopic && parentTopic.children.every(topic => newSettings.topics.includes(topic.id))) {
          newSettings.topics.push(parentTopic.id);
        }
        if (grandParentTopic && grandParentTopic.children.every(topic => newSettings.topics.includes(topic.id))) {
          newSettings.topics.push(grandParentTopic.id);
        }
        if (selectedTopic.children) {
          selectedTopic.children.forEach(child => {
            newSettings.topics.push(child.id);
            if (child.children) {
              child.children.forEach(grandChild => {
                newSettings.topics.push(grandChild.id);
              });
            }
          });
        }
      }
    } else {
      if (selectedTopic) {
        if (parentTopic) {
          newSettings.topics = newSettings.topics.filter(topic => topic !== parentTopic.id);
        }
        if (grandParentTopic) {
          newSettings.topics = newSettings.topics.filter(topic => topic !== grandParentTopic.id);
        }
        if (selectedTopic.children) {
          selectedTopic.children.forEach(child => {
            newSettings.topics = newSettings.topics.filter(topic => topic !== child.id);
            if (child.children) {
              child.children.forEach(grandChild => {
                newSettings.topics = newSettings.topics.filter(topic => topic !== grandChild.id);
              });
            }
          });
        }
      }
    }
    newSettings.topics = _.unionBy(newSettings.topics);

    setSettings({ ...newSettings });
  };

  const handleCheckType = (name, value) => {
    const newSettings = { ..._.cloneDeep(settings), infoTypes: value.map(v => v.id) };
    setSettings({ ...newSettings });
  };

  const update = async () => {
    await dispatch(clear());
    await dispatch(updateNewsletterSettings({ uuid: user.uuid, settings }));
    await dispatch(
      getNewsletterSettings({
        uuid: user.uuid,
        lang: user ? user.locale : 'en'
      })
    );
    await dispatch(updateProfile(userData));
    await getUserInfo(userData.roles, userData.uuid);
    setTimeout(() => {
      dispatch(clear());
    }, 3000);
  };

  const handleOpenChildren = topicId => {
    setCustomTopicList(
      customTopicList.map(l1 => ({
        ...l1,
        open: topicId === l1.id ? !l1.open : l1.open,
        children: l1.children?.map(l2 => ({
          ...l2,
          open: topicId === l2.id ? !l2.open : l2.open,
          children: l2.children?.map(l3 => ({ ...l3 }))
        }))
      }))
    );
  };

  const valueInfoType = settings.infoTypes.map(iT => infoTypeList.find(iTL => iTL.id === iT));

  if (!load) return <Loader />;
  return (
    <Grid container={true} sx={{ paddingBottom: 20 }}>
      <h2 className="page_title">{t('profile.menu.notification')}</h2>
      <Grid item xs={12}>
        <Grid
          container
          alignItems={`center`}
          className="notification-form-grid"
          sx={{ marginBottom: 10, marginTop: 4 }}>
          <Grid item xs={6}>
            <p className="form-label">{t('profile.newsletter.pushNotificationSubcription')}</p>
          </Grid>
          <Grid item>
            <SwitchLabel
              label={
                userData.notifications ? t('profile.newsletter.label.enabled') : t('profile.newsletter.label.disabled')
              }
              name="notification"
              onChange={() => setUserData({ ...userData, notifications: !userData.notifications })}
              checked={userData.notifications}
            />
          </Grid>
        </Grid>
        <h4 className="sub_head sub_head-big">{t('profile.newsletter.title')}</h4>
        <Grid container alignItems={`center`} className="notification-form-grid">
          <Grid item xs={6}>
            <p className="form-label">{t('profile.newsletter.newsletterSubscription')}</p>
          </Grid>
          <Grid item>
            <SwitchLabel
              label={settings.active ? t('profile.newsletter.label.enabled') : t('profile.newsletter.label.disabled')}
              name="notification"
              onChange={() => setSettings({ ...settings, active: !settings.active })}
              checked={settings.active}
            />
          </Grid>
        </Grid>
        {settings.active && (
          <>
            <Grid container className="notification-form-grid" sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <p className="form-label">{t('profile.newsletter.frequency')}</p>
              </Grid>
              <Grid item sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                <Grid>
                  <RadioBox
                    style={{ cursor: 'pointer' }}
                    label={t('profile.newsletter.label.monthly')}
                    boxProps={{
                      onChange: () => setSettings({ ...settings, frequency: 'monthly' })
                    }}
                    inputProps={{}}
                    checked={settings.frequency === 'monthly'}
                  />
                </Grid>
                <Grid>
                  <RadioBox
                    style={{ cursor: 'pointer' }}
                    label={t('profile.newsletter.label.weekly')}
                    boxProps={{
                      onChange: () => setSettings({ ...settings, frequency: 'weekly' })
                    }}
                    inputProps={{}}
                    checked={settings.frequency === 'weekly'}
                  />
                </Grid>
                <Grid>
                  <RadioBox
                    style={{ cursor: 'pointer' }}
                    label={t('profile.newsletter.label.daily')}
                    boxProps={{
                      onChange: () => setSettings({ ...settings, frequency: 'daily' })
                    }}
                    inputProps={{}}
                    checked={settings.frequency === 'daily'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="notification-form-grid" sx={{ marginTop: 4 }}>
              <Grid item xs={6}>
                <p className="form-label">{t('profile.newsletter.documentTypes')}</p>
              </Grid>
              <Grid item xs={5}>
                <MultiSelectDropdownLight
                  className="multiselect-dropdown light field-input-select multiselect-types"
                  listdata={infoTypeList || []}
                  setdatabyname={handleCheckType}
                  name="infoType"
                  defaultValue={valueInfoType || []}
                  value={valueInfoType || []}
                />
              </Grid>
            </Grid>
            <Grid>
              <h4 className="sub_head">{t('profile.newsletter.interests')}</h4>
              {customTopicList.map(topic => {
                const defaultChecked = Array.isArray(settings.topics) ? !!settings.topics.includes(topic.id) : true;
                return (
                  <Grid key={topic.id} sx={{ paddingLeft: 1 }}>
                    <Box
                      className="interest-topic-item"
                      sx={{
                        background: 'white',
                        padding: '9px 24px',
                        marginTop: 1,
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <CheckLabelTransparentLight
                        label={topic.name}
                        checked={defaultChecked}
                        value={topic.id}
                        onChange={handleCheckTopic}
                      />
                      {topic.children?.length > 0 && (
                        <IconLabelButtons
                          endIcon={topic.open ? <ChevronUp color="#000" /> : <ChevronDown color="#000" />}
                          type="button"
                          className="customButton noLabel small-tailless in-noti-setting"
                          onClick={() => handleOpenChildren(topic.id)}
                        />
                      )}
                    </Box>
                    {topic.children?.length > 0 && (
                      <Grid
                        container={true}
                        sx={{ paddingLeft: 5, flexDirection: 'column', display: topic.open ? 'block' : 'none' }}>
                        {topic.children.map(topicLv2 => {
                          const defaultCheckedLv2 = Array.isArray(settings.topics)
                            ? !!settings.topics.includes(topicLv2.id)
                            : true;
                          return (
                            <Fragment key={topicLv2.id}>
                              <Box
                                className="interest-topic-item"
                                sx={{
                                  background: 'rgba(2552,255,255,.6)',
                                  padding: '9px 24px',
                                  marginTop: 1,
                                  borderRadius: 1,
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}>
                                <CheckLabelTransparentLight
                                  label={topicLv2.name}
                                  checked={defaultCheckedLv2}
                                  value={topicLv2.id}
                                  onChange={handleCheckTopic}
                                />
                                {topicLv2.children?.length > 0 && (
                                  <IconLabelButtons
                                    endIcon={topicLv2.open ? <ChevronUp color="#000" /> : <ChevronDown color="#000" />}
                                    type="button"
                                    className="customButton noLabel small-tailless in-noti-setting"
                                    onClick={() => handleOpenChildren(topicLv2.id)}
                                  />
                                )}
                              </Box>
                              {topicLv2.children?.length > 0 && (
                                <Grid
                                  container={true}
                                  sx={{
                                    paddingLeft: 5,
                                    flexDirection: 'column',
                                    display: topicLv2.open ? 'block' : 'none'
                                  }}>
                                  {topicLv2.children.map(topicLv3 => {
                                    const defaultCheckedLv3 = Array.isArray(settings.topics)
                                      ? !!settings.topics.includes(topicLv3.id)
                                      : true;
                                    return (
                                      <Box
                                        className="interest-topic-item"
                                        sx={{
                                          background: 'rgba(2552,255,255,.4)',
                                          padding: '14px 24px',
                                          marginTop: 1,
                                          borderRadius: 1
                                        }}
                                        key={topicLv3.id}>
                                        <CheckLabelTransparentLight
                                          label={topicLv3.name}
                                          checked={defaultCheckedLv3}
                                          value={topicLv3.id}
                                          onChange={handleCheckTopic}
                                        />
                                      </Box>
                                    );
                                  })}
                                </Grid>
                              )}
                            </Fragment>
                          );
                        })}
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}

        {updateNewsletterSettingsErrorMessage && (
          <Grid className="validationResult" item xs={12} lg={11}>
            <span className="errorMsg">{updateNewsletterSettingsErrorMessage}</span>
          </Grid>
        )}
        {updateNewsletterSettingsSuccess && (
          <Grid className="validationResult" item xs={12} lg={11}>
            <span className="successMsg">{t('profile.newsletter.successMessage')}</span>
          </Grid>
        )}

        <Grid item xs={12} xl={11} sx={{ marginTop: 4 }}>
          <div style={{ textAlign: 'right' }}>
            {/* <IconLabelButtons type="button" label={t('profile.personalData.Abortbtn')} className="customButton" onClick={goBack} bgcolor="rgba(0, 0, 0, 0.15) !important" /> */}
            <IconLabelButtons
              endIcon={<CheckIcon />}
              type="button"
              label={t('profile.personalData.saveBtn')}
              className="customButton dark"
              onClick={update}
              sx={{ marginLeft: 2 }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default NewsLetter;

/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  getTopicList,
  getContentList,
  getTopicContentList,
  getTopicInfoType,
  selectedTopic,
  getContentDetailById,
  getNewDetailById,
  getTopicContentListNextPage,
  getTopicContentListByInfoType,
  getTopicContentListInfoTypeNextPage,
  setSelectedInfoTypeTab,
  setFilterOptions,
  setSelectedBrand,
  getTopicListByBrand,
  setTopicContentList,
  getTopicListForAdmin
} from 'src/store/action/topic.Action';

const initialState = {
  topicList: [],
  topicListForAdmin: [],
  editorContentList: [],
  ownContentList: [],
  editorContentLoading: false,
  ownContentLoading: false,
  topicContentList: [],
  topicInfoType: [],
  selectedTopicItem: undefined,
  topicNextCursor: undefined,
  contentNextCursor: undefined,
  ownContentNextCursor: undefined,
  topicId: undefined,
  subTopicContentList: [],
  infoTypeId: undefined,
  selectedInfoTypeTab: 0,
  filterOptions: {
    order: 'created',
    sort: 'DESC'
  },
  selectedBrand: null,
  topicListByBrand: []
};

const topicReducer = createReducer(initialState, builder => {
  builder
    .addCase(getTopicList.fulfilled, (state, action) => {
      const topicOrdered = [...action.payload.topics];
      topicOrdered.sort((a, b) => a.name.localeCompare(b.name));
      topicOrdered.forEach((_, i) => {
        if (topicOrdered[i].children) {
          topicOrdered[i].children.sort((a, b) => a.name.localeCompare(b.name));
          topicOrdered[i].children.forEach((_, j) => {
            if (topicOrdered[i].children[j].children) {
              topicOrdered[i].children[j].children.sort((a, b) => a.name.localeCompare(b.name));
            }
          });
        }
      });
      state.topicList = topicOrdered;
    })
    .addCase(getTopicListForAdmin.fulfilled, (state, action) => {
      const topicOrdered = [...action.payload.topics];
      topicOrdered.sort((a, b) => a.name.localeCompare(b.name));
      topicOrdered.forEach((_, i) => {
        if (topicOrdered[i].children) {
          topicOrdered[i].children.sort((a, b) => a.name.localeCompare(b.name));
          topicOrdered[i].children.forEach((_, j) => {
            if (topicOrdered[i].children[j].children) {
              topicOrdered[i].children[j].children.sort((a, b) => a.name.localeCompare(b.name));
            }
          });
        }
      });
      state.topicListForAdmin = topicOrdered;
    })
    .addCase(getContentList.pending, (state, action) => {
      state.ownContentLoading = true;
      state.editorContentLoading = true;
    })
    .addCase(getContentList.fulfilled, (state, action) => {
      if (action.payload.onlyOwn) {
        state.ownContentList = action.payload.cursor
          ? state.ownContentList.concat(action.payload.listData.contentList)
          : action.payload.listData.contentList;
        state.ownContentNextCursor = action.payload.listData.nextCursor;
      } else {
        state.editorContentList = action.payload.cursor
          ? state.editorContentList.concat(action.payload.listData.contentList)
          : action.payload.listData.contentList;
        state.contentNextCursor = action.payload.listData.nextCursor;
      }
      state.ownContentLoading = false;
      state.editorContentLoading = false;
    })
    .addCase(getTopicContentList.fulfilled, (state, action) => {
      state.topicContentList = action.payload.data.contentList;
      state.topicNextCursor = action.payload.data.nextCursor;
      state.topicId = action.payload.topicId;
    })
    .addCase(getTopicContentListNextPage.fulfilled, (state, action) => {
      state.topicContentList = state.topicContentList.concat(action.payload.contentList);
      state.topicNextCursor = action.payload.nextCursor;
    })
    .addCase(getTopicContentListByInfoType.fulfilled, (state, action) => {
      state.subTopicContentList = action.payload.data.contentList;
      state.subtopicNextCursor = action.payload.data.nextCursor;
      state.infoTypeId = action.payload.infoTypeId;
    })
    .addCase(getTopicContentListInfoTypeNextPage.fulfilled, (state, action) => {
      state.subTopicContentList = state.subTopicContentList.concat(action.payload.contentList);
      state.subtopicNextCursor = action.payload.nextCursor;
    })
    .addCase(getTopicInfoType.fulfilled, (state, action) => {
      state.topicInfoType = action.payload.infoTypes;
    })
    .addCase(getContentDetailById.fulfilled, (state, action) => {
      state.contentDetails = action.payload;
    })
    .addCase(getNewDetailById.fulfilled, (state, action) => {
      state.newsDetails = action.payload;
    })
    .addCase(selectedTopic, (state, action) => {
      state.selectedTopicItem = action.payload;
    })
    .addCase(setSelectedInfoTypeTab, (state, action) => {
      state.selectedInfoTypeTab = action.payload.tabIndex;
    })
    .addCase(setFilterOptions, (state, action) => {
      state.filterOptions = action.payload;
    })
    .addCase(setSelectedBrand, (state, action) => {
      state.selectedBrand = action.payload.brand;
    })
    .addCase(getTopicListByBrand.fulfilled, (state, action) => {
      const topicOrdered = action.payload.topics ? [...action.payload.topics] : null;
      if (topicOrdered) {
        topicOrdered.sort((a, b) => a.name.localeCompare(b.name));
        topicOrdered.forEach((_, i) => {
          if (topicOrdered[i].children) {
            topicOrdered[i].children.sort((a, b) => a.name.localeCompare(b.name));
            topicOrdered[i].children.forEach((_, j) => {
              if (topicOrdered[i].children[j].children) {
                topicOrdered[i].children[j].children.sort((a, b) => a.name.localeCompare(b.name));
              }
            });
          }
        });
      }
      state.topicListByBrand = topicOrdered;
    })
    .addCase(setTopicContentList, (state, action) => {
      state.topicContentList = action.payload;
    })
    .addDefaultCase(() => {});
});

export default topicReducer;

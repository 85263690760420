/** @format */

import { css, cx } from '@emotion/css';
import { SwipeableDrawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, postDetailLayerAction } from 'src/store/action/detailsLayerAction';
import TopicPageContent from 'src/views/TopicPage/topicPageContent';

const PostDetailLayer = () => {
  const dispatch = useDispatch();
  const postDetailOpenState = useSelector(state => state.detailsLayerReducer.postDetailOpenState);
  const postDetailLayerWidth = useSelector(state => state.detailsLayerReducer.postDetailWidth);
  const postDetailLayerData = useSelector(state => state.detailsLayerReducer.postDetailLayerData);
  const toggleDetails = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(postDetailLayerAction(openEvent));
  };
  const handleClosePostDetailLayer = () => {
    dispatch(clearData());
    dispatch(postDetailLayerAction(!postDetailOpenState));
  };

  const customCSS = {
    previewLayer: css`
      &.MuiPaper-root {
        background: #fffdfa;
        padding: 40px 60px;
        border-radius: 40px 0 0 40px;
        width: ${postDetailLayerWidth}%;
        @media screen and (max-width: 768px) {
          padding: 20px 16px;
        }
      }
    `
  };
  return (
    <SwipeableDrawer
      anchor={'right'}
      onClose={toggleDetails(false)}
      onOpen={toggleDetails(true)}
      PaperProps={{ className: `${cx(customCSS.previewLayer)}` }}
      ModalProps={{ sx: { zIndex: 6000 } }}
      open={postDetailOpenState}>
      {postDetailLayerData && (
        <TopicPageContent handleClosePostDetailLayer={handleClosePostDetailLayer} postDetail={postDetailLayerData} />
      )}
    </SwipeableDrawer>
  );
};

export default PostDetailLayer;

/** @format */

import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const OutlinedInputFieldsLight = ({ inputRef, InputProps, InputLabelProps, inputProps, fullWidth, ...props }) => {
  const { error, multiline, ...restProps } = props;
  return (
    <Box
      // component="form"
      // sx={{
      //     '& .MuiTextField-root': { m: 1 },
      // }}
      noValidate
      autoComplete="off"
      {...restProps}
      error={props.error ? props.error.toString() : ''}>
      <TextField
        className="text-fields light"
        id="outlined-error"
        multiline={multiline}
        {...restProps}
        fullWidth={fullWidth}
        inputProps={inputProps}
        InputProps={InputProps}
        inputRef={inputRef}
        error={!!error}
      />
    </Box>
  );
};

export default OutlinedInputFieldsLight;

/** @format */

import { css, cx } from '@emotion/css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CloseButton from 'src/components/Buttons/closeButton';
import { LeftArrow } from 'src/components/Icons';
import useAuth from 'src/hooks/useAuth';
import { marketingPostById } from 'src/store/action/marketing.action';
import { getContentDetailById, getNewDetailById } from 'src/store/action/topic.Action';
import Errorspage from '../errors';
import TopicPageContent from './topicPageContent';

const customCSS = {
  layerCloseButton: css`
    z-index: 99;
    &.close-button {
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }
  `
};

const TopicPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [param] = useSearchParams();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth();
  const [postDetails, setPostDetails] = useState();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const type = location.pathname.split('/', 2)[1];

      if (type === 'content') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(getContentDetailById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.contentPost);
          } else {
            setNotFound(true);
          }
        });
      } else if (type === 'news') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(getNewDetailById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.newsPost);
          } else {
            setNotFound(true);
          }
        });
      } else if (type === 'marketing') {
        const payload = {
          lang: user ? user.locale : 'en',
          id: pageId,
          contentLang: param.get('lang') ? param.get('lang') : ''
        };
        dispatch(marketingPostById(payload)).then(data => {
          if (data.payload) {
            setPostDetails(data.payload.marketingPost);
          } else {
            setNotFound(true);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClosePostDetailLayer = () => {
    navigate('/topics');
  };

  if (notFound) {
    return (
      <>
        <CloseButton
          label="Back"
          startclose={+true}
          startIcon={<LeftArrow />}
          onClick={() => handleClosePostDetailLayer()}
          className={`close-button dark ${cx(customCSS.layerCloseButton)}`}
        />
        <Errorspage />
      </>
    );
  }
  if (postDetails) {
    return <TopicPageContent handleClosePostDetailLayer={handleClosePostDetailLayer} postDetail={postDetails} />;
  }
  return <></>;
};

export default TopicPage;

/** @format */

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { Box, SwipeableDrawer } from '@mui/material';
import IconLabelButtons from './Buttons';
import { AddIcon, AppIcon } from './Icons';
import PostSelectionLayer from 'src/views/editor/postCreationSteps/postSelectionLayer';
import { editorOpen } from 'src/store/action/editor.Action';
import { useTranslation } from 'react-i18next';
import { editorStepAction } from 'src/store/action/editor.Action';
import { CONTENT_LAYER_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';
import { adminTopicsEdit, adminTopicsEditorOpen } from 'src/store/action/adminTopics.Action';
import AdminTopicsEditor from 'src/views/adminTopics/editor';

export const DashboardHeader = ({ text, ...rest }) => {
  const { t } = useTranslation();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const dispatch = useDispatch();
  const handleOpenNav = () => {
    dispatch(navigationOpenAction(true));
    dataLayerPushEvent(CONTENT_LAYER_EVENT, {
      action: 'open'
    });
  };
  return (
    <div className={`page_header`} {...rest}>
      <h1>{text}</h1>
      {isMobile || navOpen ? null : (
        <Box>
          <IconLabelButtons
            endIcon={<AppIcon />}
            type="button"
            label={t('dashboard_CTA')}
            className="customButton dark"
            onClick={() => handleOpenNav()}
          />
        </Box>
      )}
    </div>
  );
};

export const EditorHeader = ({ text }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(state => state.navigationReducer.mobileWidth);
  const navOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const openState = useSelector(state => state.editorReducer.openState);
  const { t } = useTranslation();

  const handleOpenNav = () => {
    dispatch(navigationOpenAction(true));
  };

  const toggleDrawer = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(editorOpen(openEvent));
    dispatch(editorStepAction(0));
  };

  const boxContent = () => <PostSelectionLayer />;

  return (
    <Box className="page_header">
      <h1>{text}</h1>
      <Box>
        {isMobile || navOpen ? null : (
          <IconLabelButtons
            type="button"
            variant="outlined"
            label="Filter"
            className="outlined-button"
            onClick={() => handleOpenNav()}
          />
        )}
        {isMobile ? (
          <Fragment>
            <IconLabelButtons
              endIcon={<AddIcon color="#ffffff" />}
              type="button"
              className="customButton noLabel dark borderless addicon"
              onClick={toggleDrawer(true)}
            />
            <SwipeableDrawer
              anchor={'bottom'}
              open={openState}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              PaperProps={{ className: 'custom-swipeable custom-swipeable-mobile' }}
              ModalProps={{ sx: { zIndex: 6000 }, disableEnforceFocus: true }}>
              {boxContent()}
            </SwipeableDrawer>
          </Fragment>
        ) : (
          <Fragment>
            <IconLabelButtons
              endIcon={<AddIcon color="#ffffff" />}
              type="button"
              label={t('editorBase.addContent')}
              className="customButton dark addicon"
              onClick={toggleDrawer(true)}
            />
            <SwipeableDrawer
              anchor={'right'}
              open={openState}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              PaperProps={{ className: 'custom-swipeable' }}
              ModalProps={{ sx: { zIndex: 1200 }, disableEnforceFocus: true }}>
              {boxContent()}
            </SwipeableDrawer>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export const AdminEditorHeader = ({ text }) => {
  const dispatch = useDispatch();
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const openState = useSelector(state => state.adminTopicsReducer.openState);
  const { t } = useTranslation();

  const toggleDrawer = openEvent => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(adminTopicsEditorOpen(openEvent));
    dispatch(adminTopicsEdit(null));
  };

  return (
    <Box className="page_header page_header-adminTopics">
      <h1>{text}</h1>
      {!checkNavOpen && (
        <Box className="page_header-buttons">
          <IconLabelButtons
            type="button"
            variant="outlined"
            label={t('adminTopics.filter')}
            className="outlined-button"
            onClick={() => dispatch(navigationOpenAction(true))}
          />
          <IconLabelButtons
            endIcon={<AddIcon color="#ffffff" />}
            type="button"
            label={t('adminTopics.addTopic')}
            className="customButton dark addicon"
            onClick={() => dispatch(adminTopicsEditorOpen(true))}
          />
        </Box>
      )}
      <SwipeableDrawer
        anchor={'right'}
        open={openState}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{ className: 'custom-swipeable' }}
        ModalProps={{ sx: { zIndex: 1200 }, disableEnforceFocus: true }}>
        <AdminTopicsEditor />
      </SwipeableDrawer>
    </Box>
  );
};

export const TopicsHeader = ({ heading, subHeading }) => {
  // const dispatch = useDispatch();
  // const handleOpenNav = () => {
  //   dispatch(navigationOpenAction(true))
  // }
  return (
    <Box className="page_header" sx={{ display: 'block' }}>
      <h1>{heading}</h1>
      {subHeading && <h4 className="page_header_topicsSubHeading">{subHeading}</h4>}
    </Box>
  );
};

export const SearchHeader = ({ heading }) => {
  return (
    <Box className="page_header" sx={{ display: 'block' }}>
      <h1>{heading}</h1>
    </Box>
  );
};

export const UserHeader = props => {
  return <h1>{props.text}</h1>;
};

export const DefaultHeader = props => {
  return (
    <div className={``}>
      <h1>Default Header</h1>
      <span>sub header</span>
    </div>
  );
};

/** @format */

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ComponentTools } from './comTools';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import UploadDocuments from '../uploadDocuments';
import { useState } from 'react';
import { COMPONENT_ACTION } from 'src/constants';

const allowedContentTypes =
  '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.exe,.txt,.zip,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/csv,text/plain,application/zip';

export default function ComDocument({ components, actionComponent, com, index }) {
  const { t } = useTranslation();
  const [error, setError] = useState({
    title: false,
    description: false,
    files: false
  });

  const fieldValidate = e => {
    const { name, value } = e;
    setError(_error => ({ ...error, [name]: !value }));
  };
  const setContactValue = (e, name) => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, {
      component: com,
      value: com.value ? { ...com.value, [name]: e.target.value } : { [name]: e.target.value }
    });
  };
  const setFileData = data => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, {
      component: com,
      value: com.value ? { ...com.value, files: data } : { files: data }
    });
  };

  return (
    <Box className="pageBuilder-contentFieldsWrap">
      <Box className="field-label">
        <span>{t('editorContent.content.document')}*</span>
        <ComponentTools components={components} action={actionComponent} component={com} />
      </Box>
      <Box className="fields-column-direction">
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.documentTitle')}*`}
          name={`document-title-${index}`}
          onChange={e => setContactValue(e, 'title')}
          defaultValue={com.value?.title || ''}
          onBlur={e => fieldValidate({ name: 'title', value: e.target.value })}
          error={error.title}
        />
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.documentDescription')}*`}
          name={`document-description-${index}`}
          onChange={e => setContactValue(e, 'description')}
          defaultValue={com.value?.description || ''}
          onBlur={e => fieldValidate({ name: 'description', value: e.target.value })}
          error={error.description}
        />
        <UploadDocuments
          name={`document-upload-${index}`}
          setDataByName={(name, value) => {
            if (name === `document-upload-${index}`) setFileData(value);
            else if (name === `errUploadDocument`) setError(_error => ({ ..._error, files: value }));
          }}
          accept={allowedContentTypes}
          dataValue={com.value?.files ? com.value.files : []}
          type="document"
          errUploadDocument={error.files}
          filesLimit={1}
        />
      </Box>
    </Box>
  );
}

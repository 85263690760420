/** @format */

import React, { Children, Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AddIcon, DeleteIcon } from 'src/components/Icons';
import { useTranslation } from 'react-i18next';
import { css, cx } from '@emotion/css';
import { formatBytes } from 'bytes-formatter';
import CloseButton from 'src/components/Buttons/closeButton';

const customCSS = {
  profileUploader: css`
    min-height: 156px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.4);
    padding: 35px;
    position: relative;
    display: flex;
    justify-content: center;
  `,
  profileUploaderTwo: css`
    min-height: 60px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 10px 5px 10px 20px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  actionMenu: css`
    .close-button {
      &:hover {
        svg {
          path {
            fill: #ffa500;
          }
        }
      }
    }
  `,
  profileUploaderThree: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  fileUploader: css`
    z-index: 1;
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  `,
  uploadText: css`
    font-size: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  fileName: css`
    font-size: 18px;
    line-height: 29px;
    color: #000000;
  `,
  fileSize: css`
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.4);
  `
};

const UploadDocuments = ({ dataValue, setDataByName, name, accept, type, errUploadDocument, filesLimit }) => {
  const [deletedFiles, setDeletedFiles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (dataValue && dataValue.length) {
      if (dataValue.some(file => file.size > 134217728)) {
        dataValue.forEach(file => {
          if (file.size > 134217728) {
            const sizePretty = formatBytes(file.size);
            setDataByName(
              'errUploadDocument',
              t('profile.personalData.profile_errors.maxSize', { fileSize: sizePretty, limit: '128 MB' })
            );
            return;
          }
        });
      } else if (dataValue.length >= filesLimit + 1) {
        setDataByName(
          'errUploadDocument',
          t('profile.personalData.profile_errors.filesLimit', { numberOfFiles: filesLimit })
        );
      } else {
        setDataByName('errUploadDocument', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue, t]);

  const changeHandler = event => {
    let newFiles = [];
    const files = event.target.files;
    Object.keys(files).forEach(x => newFiles.push(files[x]));

    if (files.length) {
      const updatedFilesList = dataValue ? dataValue.concat(newFiles) : newFiles;
      setDataByName(name, updatedFilesList);
    }
  };

  const NoFileSelected = () => {
    return (
      <Box className={cx(customCSS.profileUploader)}>
        <input
          type="file"
          className={cx(customCSS.fileUploader)}
          multiple={filesLimit > 1}
          name="documents"
          accept={accept}
          onChange={changeHandler}
        />
        <div className={cx(customCSS.uploadText)}>
          <AddIcon color="rgba(0,0,0,0.4)" width="18.05" height="18.05" />
          <Box sx={{ mt: '22px', color: 'rgba(0, 0, 0, 0.4)', fontSize: '17px' }}>
            {type === 'video'
              ? t('editorContent.content.video_file_placeHolder_next')
              : type === 'document'
              ? t('editorContent.content.document_file_placeHolder_next')
              : t('editorContent.content.file_placeHolder_next')}
          </Box>
          <Box component="span" sx={{ mt: '22px', color: 'rgba(0, 0, 0, 0.4)', fontSize: '14px' }}>
            {type === 'video'
              ? t('editorContent.content.file_Video_Type')
              : type === 'document'
              ? t('editorContent.content.file_Document_Type')
              : t('editorContent.content.file_Type')}
          </Box>
        </div>
      </Box>
    );
  };

  const fileDelete = (key, fileId) => {
    if (dataValue && dataValue.length > 1) {
      const updatedFilesList = [...dataValue];
      updatedFilesList.splice(key, 1);
      setDataByName(name, updatedFilesList);
    } else {
      setDataByName(name, []);
    }

    if (fileId) {
      const currentDeletedFiles = [...deletedFiles, fileId];
      setDeletedFiles(currentDeletedFiles);
      setDataByName('deletedDocuments', currentDeletedFiles);
    }
  };

  const FileSelected = () => {
    return (
      <>
        {dataValue && dataValue.length
          ? Children.toArray(
              dataValue.map((file, key) => {
                const { size, name, title, id } = file;
                return (
                  <Box className={cx(customCSS.profileUploaderTwo, size > 134217728 && `errBorder`)}>
                    <Box className={cx(customCSS.uploadText)} alignItems="flex-start">
                      <Box className={cx(customCSS.fileName)}>{title ? title : name}</Box>
                      <Box className={cx(customCSS.fileSize, size > 134217728 && `errColor`)}>{formatBytes(size)}</Box>
                    </Box>
                    <Box className={cx(customCSS.actionMenu)}>
                      <CloseButton
                        onClick={() => fileDelete(key, id)}
                        endIcon={<DeleteIcon />}
                        className="close-button light"
                      />
                    </Box>
                  </Box>
                );
              })
            )
          : null}

        {dataValue && dataValue.length < filesLimit ? (
          <Box className="addFiles">
            <Box className={cx(customCSS.profileUploaderThree)}>
              <input
                type="file"
                className={cx(customCSS.fileUploader)}
                multiple
                name="documents"
                accept={accept}
                onChange={changeHandler}
              />
              <AddIcon color="rgba(0, 0, 0, 0.4)" width="18.05" height="18.05" />
              <Box component="span" sx={{ mt: '22px', color: 'rgba(0, 0, 0, 0.4)', fontSize: '17px' }}>
                {type === 'video'
                  ? t('editorContent.content.video_file_placeHolder_next')
                  : type === 'document'
                  ? t('editorContent.content.document_file_placeHolder_next')
                  : t('editorContent.content.file_placeHolder_next')}
              </Box>
              <Box component="span" sx={{ mt: '22px', color: 'rgba(0, 0, 0, 0.4)', fontSize: '14px' }}>
                {type === 'video'
                  ? t('editorContent.content.file_Video_Type')
                  : type === 'document'
                  ? t('editorContent.content.file_Document_Type')
                  : t('editorContent.content.file_Type')}
              </Box>
            </Box>
          </Box>
        ) : null}

        {errUploadDocument && <Box className="errColor errorMessage">{errUploadDocument}</Box>}
      </>
    );
  };

  return <div>{dataValue && dataValue.length ? <FileSelected /> : <NoFileSelected />}</div>;
};

export default UploadDocuments;

/** @format */
import { createReducer } from '@reduxjs/toolkit';
import {
  searchKeyword,
  searchParamsAction,
  searchOption,
  deleteSearchOption,
  clearFilters,
  clearCursor,
  nextPageResult
} from '../action/search.actions';
import _ from 'lodash';

const initialState = {
  searchResults: null,
  searchLatestResults: null,
  searchLoading: false,
  searchLatestLoading: false,
  widgetResults: undefined,
  searchParams: undefined,
  clauses: [],
  nextCursor: undefined,
  nextLatestCursor: undefined
};

const searchReducer = createReducer(initialState, builder => {
  builder
    .addCase(searchKeyword.pending, (state, action) => {
      state.searchLoading = true;
      state.searchLatestLoading = true;
    })
    .addCase(searchKeyword.fulfilled, (state, action) => {
      if (action.meta.arg.order === 'latest') {
        state.searchLatestResults = action.payload.searchResults;
        state.nextLatestCursor = action.payload.nextCursor;
      } else {
        state.searchResults = action.payload.searchResults;
        state.nextCursor = action.payload.nextCursor;
      }
      state.searchLoading = false;
      state.searchLatestLoading = false;
    })
    .addCase(nextPageResult.pending, (state, action) => {
      state.searchLoading = true;
      state.searchLatestLoading = true;
    })
    .addCase(nextPageResult.fulfilled, (state, action) => {
      if (action.meta.arg.order === 'latest') {
        state.searchLatestResults = state.searchLatestResults.concat(action.payload.searchResults);
        state.nextLatestCursor = action.payload.nextLatestCursor;
      } else {
        state.searchResults = state.searchResults.concat(action.payload.searchResults);
        state.nextCursor = action.payload.nextCursor;
      }
      state.searchLoading = false;
      state.searchLatestLoading = false;
    })
    .addCase(searchParamsAction, (state, action) => {
      state.searchParams = action.payload;
    })
    .addCase(searchOption, (state, action) => {
      const clauseUniq = _.uniq([...state.clauses, action.payload]);
      state.clauses = clauseUniq;
    })
    .addCase(deleteSearchOption, (state, action) => {
      state.clauses = state.clauses.filter(clause => clause !== action.payload);
    })
    .addCase(clearFilters, state => {
      state.clauses = initialState.clauses;
    })
    .addCase(clearCursor, state => {
      state.nextCursor = initialState.nextCursor;
    })
    .addDefaultCase((state, action) => {});
});

export default searchReducer;

/** @format */

import React from 'react';
import { Box, Link, Stack, ButtonBase } from '@mui/material';
import SearchField from 'src/components/Search';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// import { cardLayerAction, cardLayerWidth, cardLayerData } from 'src/store/action/cardLayerAction';

// import { useEffect } from 'react';
import { ChevronDuoRightLgSm } from 'src/components/Icons';
import { useNavigate } from 'react-router-dom';
import { searchKeyword, searchOption } from 'src/store/action/search.actions';
import { useTranslation } from 'react-i18next';
import { SEARCH_EVENT, dataLayerPushEvent } from 'src/utils/dataLayer';

const SearchWidget = props => {
  // const cardLayerOpen = useSelector(state => state.cardLayerReducer.cardLayerOpen);
  // const widgetResults = useSelector(state => state.searchReducer.widgetResults);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const onSearchClick = () => {
    const payload = {
      keywords: searchData.split(' ')
    };
    dispatch(searchKeyword(payload));
    dispatch(searchKeyword({ ...payload, order: 'latest' }));
    dispatch(searchOption(searchData));

    dataLayerPushEvent(SEARCH_EVENT, {
      keyword: searchData,
      place: 'Search Widget'
    });

    navigate('/search');
  };

  const onLastSearchClick = keywords => {
    const payload = {
      keywords
    };
    dispatch(searchKeyword(payload));
    dispatch(searchKeyword({ ...payload, order: 'latest' }));
    for (let index = 0; index < keywords.length; index++) {
      dispatch(searchOption(keywords[index]));
    }

    dataLayerPushEvent(SEARCH_EVENT, {
      keyword: keywords.join(' '),
      place: 'Last Search Widget'
    });

    navigate('/search');
  };

  const lastSearches =
    props.dashboards && props.dashboards.lastSearches && props.dashboards.lastSearches.length
      ? props.dashboards.lastSearches.slice(0, 3)
      : [];
  return (
    <Box className="card-container no-cta" sx={{ background: 'rgba(0, 0, 0, 0.08)' }}>
      <h3>{t('searchPage.title')}</h3>
      <Stack className="search-wrapper">
        <SearchField
          placeholder={t('keywords')}
          onChangeSearch={e => setSearchData(e.target.value)}
          searchData={searchData}
          classes="dark"
          onClick={onSearchClick}
        />

        <Box sx={{ mt: 2 }}>
          <Box className="search-head">{t('searchContent.lastSearch')}</Box>

          {lastSearches.map((item, i) => {
            return (
              <Link key={i} href="#" className="link-text result-wrap" underline="none">
                <ButtonBase onClick={() => onLastSearchClick(item.keywords)}>
                  <Box className="result-image">
                    <Box className="img-hover-effect">
                      <ChevronDuoRightLgSm color="rgba(255,255,255,.5)" />
                    </Box>
                  </Box>
                  <Box className="result-text">{item.title}</Box>
                </ButtonBase>
              </Link>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

export default SearchWidget;

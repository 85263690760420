/** @format */

import { Box, FormControl, Input, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
// import { getUserRoleByLang } from 'src/store/action/admin.Action';
import { updatUserRoles } from 'src/store/action/publicPage.Action';
import { getUserList, updateUser } from 'src/store/action/user.Action';
import { countriesMap, marketMap } from 'src/utils/market';

const countries = [];
countriesMap.forEach((country, keys) => {
  countries.push({ country, keys });
});

export default function UserEditor({ user, i, market, setMarket, setIsOpen, roles, setRoles }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allRoles = useSelector(state => state.adminReducer.roles);
  const userListPayload = useSelector(state => state.userReducer.userListPayload);
  const [data, setData] = useState(user);
  const [markets, setMarkets] = useState([{ id: market, name: marketMap.get(market) }]);
  const [errors, setErrors] = useState([]);

  const updateUserData = async () => {
    setErrors({});
    if (data.roles?.length === 0 || !data.roles) {
      setErrors({ roles: t('user.roleRequired') });
    } else {
      await dispatch(updatUserRoles({ uuid: user.uuid, roles: data.roles }));
      await dispatch(updateUser({ uuid: user.uuid, data }));
      await dispatch(getUserList(userListPayload));
      setIsOpen(undefined);
    }
  };

  useEffect(() => {
    if (!data.roles || data.roles?.length !== 0) setErrors({});
    setRoles(data.roles || []);
  }, [setRoles, data]);

  return (
    <div className="user-editor">
      <Box className="user-editor-columns">
        <Box className="user-editor-col">
          <h3 className="user-editor-col-title">{t('user.userData')}</h3>
          <Box className="attribute firstname">
            <label>{t('user.firstname')}</label>
            <Input
              name="firstName"
              value={data.firstName}
              onChange={e => setData({ ...data, firstName: e.target.value })}
            />
          </Box>
          <Box className="attribute lastname">
            <label>{t('user.lastname')}</label>
            <Input
              name="lastName"
              value={data.lastName}
              onChange={e => setData({ ...data, lastName: e.target.value })}
            />
          </Box>
          <Box className="attribute email">
            <label>{t('user.email')}</label>
            <Input name="email" value={data.email} disabled />
          </Box>
          <Box className="attribute phone">
            <label>{t('user.phone')}</label>
            <Input
              name="phoneNumber"
              value={data.address.phoneNumber}
              onChange={e => setData({ ...data, address: { ...data.address, phoneNumber: e.target.value } })}
            />
          </Box>
          <Box className="attribute company">
            <label>{t('user.company')}</label>
            <Input name="company" value={data.company} onChange={e => setData({ ...data, company: e.target.value })} />
          </Box>
          <Box className="attribute address">
            <label>{t('user.address')}</label>
            <Input
              name="street"
              value={data.address.street}
              onChange={e => setData({ ...data, address: { ...data.address, street: e.target.value } })}
            />
          </Box>
          <Box className="attribute country">
            <label>{t('user.country')}</label>
            <Select
              size="small"
              labelId={`country-${i}`}
              id={`country-${i}`}
              value={data.address.country}
              onChange={e => {
                setData({ ...data, address: { ...data.address, country: [e.target.value] } });
              }}>
              {countries.map(({ country, keys }, i) => {
                return (
                  <MenuItem key={keys} value={keys}>
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
        <Box className="user-editor-col">
          <h3 className="user-editor-col-title">{t('user.universalRegistration')}</h3>
          <Box className="attribute usergroup">
            <label>{t('user.usergroup')}</label>
            <FormControl variant="standard">
              <Input value={data.scenario || 'Driver'} disabled />
            </FormControl>
          </Box>
          <Box className="attribute role">
            <label>{t('user.role')}</label>
            <FormControl variant="standard">
              <Input value={data.role || 'Fleet Manager'} disabled />
            </FormControl>
          </Box>
          <div className="user-editor-gray-box">
            <h3 className="user-editor-col-title">{t('user.extranetSettings')}</h3>
            <Box className="attribute attribute-less-margin-bottom market">
              <label>{t('user.market')}</label>
              <Input value={markets[0].name} disabled />
              {/* <FormControl variant="standard" className="multiselect-dropdown-no-margin multiselect-dropdown-read-only">
                <MultiSelectDropdownLight
                  readOnly
                  listdata={Array.from(marketMap, ([id, name]) => ({ id, name }))}
                  setdatabyname={(e, i) => {
                    setMarkets(i);
                    setMarket(i[0] ? i[0].id : null);
                    if (!i[0]) {
                      setData({ ...user, roles: [] });
                    }
                  }}
                  name="attributes"
                  value={markets}
                  defaultValue={markets}
                  style={{ marginTop: 16, marginBottom: 8 }}
                />
              </FormControl> */}
            </Box>
            <Box className="attribute attribute-less-margin-bottom feature">
              <label>{t('user.feature')} *</label>
              <FormControl variant="standard">
                <MultiSelectDropdownLight
                  listdata={allRoles[market]?.map(r => ({ id: r.name, name: r.name })) || []}
                  setdatabyname={(e, i) => setData({ ...user, roles: i.map(r => r.name) })}
                  name="attributes"
                  value={data.roles ? data.roles.map(r => ({ id: r, name: r })) : []}
                  defaultValue={data.roles ? data.roles.map(r => ({ id: r, name: r })) : []}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  error={errors.roles}
                  helperText={errors.roles}
                />
              </FormControl>
            </Box>
            <Box className="attribute dieselrepair">
              <label>{t('user.dieselrepair')}</label>
              <Input value={data.drsId || ''} onChange={e => setData({ ...data, drsId: e.target.value })} />
            </Box>
          </div>
        </Box>
        {/* <Box className="user-editor-col"></Box> */}
      </Box>
      <Box sx={{ display: 'flex !important', justifyContent: 'flex-end', marginTop: '2rem' }}>
        <IconLabelButtons
          type="button"
          label={t('user.cancel')}
          className="customButton light"
          onClick={() => setIsOpen(undefined)}
        />
        <IconLabelButtons
          endIcon={<RightArrow />}
          type="button"
          label={t('user.update')}
          className="customButton dark"
          onClick={updateUserData}
          sx={{ ml: '16px' }}
        />
      </Box>
    </div>
  );
}

/** @format */

import { Box } from '@mui/material';
import React from 'react';

import { DeleteIcon, MoveDownIcon, MoveUpIcon } from 'src/components/Icons';
import { COMPONENT_ACTION } from 'src/constants';

export function ComponentTools({ action, components, component }) {
  const index = components.findIndex(com => com.key === component.key);
  return (
    <Box className="component-tools">
      {index > 0 && (
        <button
          className="action-component move-up-component"
          onClick={() => action(COMPONENT_ACTION.MOVE_UP_COMPONENT, { component })}>
          <MoveUpIcon color="black" />
        </button>
      )}
      {index < components.length - 1 && (
        <button
          className="action-component move-down-component"
          onClick={() => action(COMPONENT_ACTION.MOVE_DOWN_COMPONENT, { component })}>
          <MoveDownIcon color="black" />
        </button>
      )}
      <button
        className="action-component remove-component"
        onClick={() => action(COMPONENT_ACTION.REMOVE_COMPONENT, { component })}>
        <DeleteIcon color="black" />
      </button>
    </Box>
  );
}

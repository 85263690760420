/** @format */
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import { ComponentTools } from './comTools';
import { useState } from 'react';
import { COMPONENT_ACTION, VALIDATE_REGEX } from 'src/constants';

export default function ComContact({ components, actionComponent, com, index }) {
  const { t } = useTranslation();
  const [error, setError] = useState({ name: false, position: false, phoneNumber: false, emailAddress: false });

  const fieldValidate = e => {
    const { name, value } = e;
    switch (name) {
      case 'phoneNumber':
        setError(_error => ({ ...error, [name]: !value || !VALIDATE_REGEX.phone.test(value) }));
        break;
      case 'emailAddress':
        setError(_error => ({ ...error, [name]: !value || !VALIDATE_REGEX.email.test(value) }));
        break;
      default:
        setError(_error => ({ ...error, [name]: !value }));
        break;
    }
  };
  const setContactValue = (e, name) => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, {
      component: com,
      value: com.value ? { ...com.value, [name]: e.target.value } : { [name]: e.target.value }
    });
  };

  return (
    <Box className="pageBuilder-contentFieldsWrap">
      <Box className="field-label">{t('editorContent.content.contactInformationTitle')}*</Box>
      <Box className="fields-contact-information">
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.name')}*`}
          name={`contact-name-${index}`}
          onChange={e => setContactValue(e, 'name')}
          defaultValue={com.value?.name || ''}
          onBlur={e => fieldValidate({ name: 'name', value: e.target.value })}
          error={error.name}
        />
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.position')}*`}
          name={`contact-position-${index}`}
          onChange={e => setContactValue(e, 'position')}
          defaultValue={com.value?.position || ''}
          onBlur={e => fieldValidate({ name: 'position', value: e.target.value })}
          error={error.position}
        />
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.phoneNumber')}*`}
          name={`contact-phoneNumber-${index}`}
          onChange={e => setContactValue(e, 'phoneNumber')}
          defaultValue={com.value?.phoneNumber || ''}
          onBlur={e => fieldValidate({ name: 'phoneNumber', value: e.target.value })}
          error={error.phoneNumber}
        />
        <OutlinedInputFieldsLight
          placeholder={`${t('editorContent.content.emailAddress')}*`}
          name={`contact-emailAddress-${index}`}
          onChange={e => setContactValue(e, 'emailAddress')}
          defaultValue={com.value?.emailAddress || ''}
          onBlur={e => fieldValidate({ name: 'emailAddress', value: e.target.value })}
          error={error.emailAddress}
        />
        <ComponentTools components={components} action={actionComponent} component={com} />
      </Box>
    </Box>
  );
}

/** @format */

import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Image1 from 'src/assets/imgs/preview-1.svg';
import Image2 from 'src/assets/imgs/preview-2.svg';
import Image3 from 'src/assets/imgs/preview-3.svg';
import Image4 from 'src/assets/imgs/preview-4.svg';
import Image5 from 'src/assets/imgs/preview-5.svg';
import IconLabelButtons from 'src/components/Buttons';
// import ImageMarketing from 'src/assets/imgs/image_marketing.png'
import { TagBasic } from 'src/components/Tags';
import { RightArrow } from 'src/components/Icons';
import { css, cx } from '@emotion/css';
import { format } from 'date-fns';
import { formatBytes } from 'bytes-formatter';

const DesktopPreview = props => {
  var w = window.innerWidth;
  const { postData, postType } = props;
  const customCSS = {
    previewImage: css`
      width: 100%;
      object-fit: cover;
      height: 525px;
      border-radius: 24px;
    `,
    desktopHeading: css`
      margin-top: 20px;
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
    `,
    description: css`
      margin-top: 10px;
      font-size: 17px;
      line-height: 25px;
      font-weight: 500;
    `,
    newshead: css`
      padding: 20px;
    `,
    newsdesktopHeading: css`
      margin-top: 20px;
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
      margin-bottom: 4rem;
    `,
    newsbody: css`
      padding: 20px;
      font-size: 18px;
      line-height: 1.5;
      h1 {
        position: relative !important;
      }
      ul,
      ol {
        font-size: 18px;
        line-height: 25px;
        padding-left: 30px;
      }
    `,
    date: css`
      font-size: 14px;
      color: #6b6b6b;
    `,
    desktopPreviewWrap: css`
      max-width: ${w >= 991 ? '1070px' : w + 'px'};
      margin: 80px auto 0;
      padding: 32px;
      overflow: auto;
      background: #fff;
      border-radius: 24px;
      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }
    `,
    fileName: css`
      font-size: 18px;
      line-height: 29px;
      color: #000000;
    `,
    fileSize: css`
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.4);
    `
  };

  return (
    <Box>
      <hr />

      {/* ************** Use this code for blank preview START *************/}

      {!postData && (
        <Box className={cx(customCSS.desktopPreviewWrap)}>
          <Box
            sx={{ display: 'flex' }}
            columnGap="62px"
            alignItems="end"
            className={cx(
              css`
                width: ${w >= 992 ? 'initial' : w <= 750 ? '750px' : w + 'px'};
              `
            )}>
            <Box sx={{ flexBasis: '50%', maxWidth: '450px' }}>
              <img src={Image1} alt="Marketing Preview" className={cx(customCSS.previewImage)} />
            </Box>
            <Box sx={{ flexBasis: '50%', maxWidth: '450px' }}>
              <Box>
                <img src={Image2} alt="Marketing Preview" className="img-fluid" />
              </Box>
              <Box sx={{ mt: '30px' }}>
                <img src={Image3} alt="Marketing Preview" className="img-fluid" />
              </Box>
              <Box sx={{ mt: '20px' }}>
                <img src={Image4} alt="Marketing Preview" className="img-fluid" />
              </Box>
              <Box sx={{ mt: '45px' }}>
                <img src={Image5} alt="Marketing Preview" className="img-fluid" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* *************** Use this code for blank preview END ****************/}
      {/* <Box sx={{ width: '1070px' }}> */}
      {postType === 'marketing' && (
        <Box className={cx(customCSS.desktopPreviewWrap)}>
          <Box
            sx={{ display: 'flex' }}
            columnGap="62px"
            alignItems="end"
            className={cx(
              css`
                width: ${w >= 992 ? 'initial' : w <= 750 ? '750px' : w + 'px'};
              `
            )}>
            <Box sx={{ flexBasis: '50%', maxWidth: '450px' }}>
              {postData && postData.picture ? (
                <img
                  src={
                    postData.picture && postData.picture.url
                      ? postData.picture.url
                      : URL.createObjectURL(postData.picture)
                  }
                  alt={''}
                  className={cx(customCSS.previewImage)}
                />
              ) : null}
            </Box>
            {/* <Box> */}
            <Box sx={{ flexBasis: '50%', maxWidth: '450px' }}>
              <Stack direction="row" columnGap={1}>
                {postData &&
                  postData.tagIds &&
                  postData.tagIds.map((item, index) => {
                    return (
                      <TagBasic key={index} content={item.value.name} bgcolor="rgba(0, 0, 0, 0.05)" color="#000" />
                    );
                  })}
                {postData &&
                  postData.newTags &&
                  postData.newTags.map((item, index) => {
                    return (
                      <TagBasic key={index} content={item.value.name} bgcolor="rgba(244, 231, 212, 1)" color="#000" />
                    );
                  })}
              </Stack>
              <h3 className={cx(customCSS.desktopHeading)}>{postData && postData.title}</h3>
              <p className={cx(customCSS.description)}>{postData && postData.text}</p>
              <Box sx={{ display: 'flex', justifyContent: 'end', mt: '30px' }}>
                <IconLabelButtons
                  endIcon={<RightArrow />}
                  type="button"
                  label={postData && postData.linkTitle}
                  className={`customButton transLabel dark endTrans`}
                  onClick={() => window.open(postData.link, '_blank')}
                />
              </Box>
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      )}
      {/* </Box> */}
      {postType === 'news' && postData && (
        <Box className={cx(customCSS.desktopPreviewWrap)}>
          <Grid container alignItems="end">
            <Grid item md={12}>
              <Box>
                <Box className={cx(customCSS.newshead)}>
                  <span className={cx(customCSS.date)}>
                    {postData && postData.releaseDate && format(new Date(postData.releaseDate), 'PPP')}
                  </span>
                  <h3 className={cx(customCSS.newsdesktopHeading)}>{postData && postData.title}</h3>
                  <Stack direction="row" columnGap={1}>
                    {postData &&
                      postData.tagIds &&
                      postData.tagIds.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value.name} bgcolor="rgba(0, 0, 0, 0.05)" color="#000" />
                        );
                      })}
                    {postData &&
                      postData.newTags &&
                      postData.newTags.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value} bgcolor="rgba(244, 231, 212, 1)" color="#000" />
                        );
                      })}
                  </Stack>
                </Box>
                <hr />
                <div
                  className={cx(customCSS.newsbody)}
                  dangerouslySetInnerHTML={{ __html: postData && postData.text }}></div>
              </Box>
            </Grid>
            {/* <Grid item md={12} sx={{ width: '100%' }}>
                        {postData ?
                            <img src={postData.picture && postData.picture.url ? postData.picture.url : URL.createObjectURL(postData.picture)} alt={""} width="100%" />:null}
                        </Grid> */}
          </Grid>
        </Box>
      )}
      {postType === 'content' && postData && (
        <Box className={cx(customCSS.desktopPreviewWrap)}>
          <Grid container alignItems="end">
            <Grid item md={12}>
              <Box>
                <Box className={cx(customCSS.newshead)}>
                  <span className={cx(customCSS.date)}>
                    {postData && postData.releaseDate && format(new Date(postData.releaseDate), 'PPP')}
                  </span>
                  <h3 className={cx(customCSS.newsdesktopHeading)}>{postData && postData.title}</h3>
                  <Stack direction="row" columnGap={1}>
                    {postData &&
                      postData.tagIds &&
                      postData.tagIds.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value.name} bgcolor="rgba(0, 0, 0, 0.05)" color="#000" />
                        );
                      })}
                    {postData &&
                      postData.newTags &&
                      postData.newTags.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value} bgcolor="rgba(244, 231, 212, 1)" color="#000" />
                        );
                      })}
                  </Stack>
                </Box>
                <hr />
                <br />
                {postData && postData.documents ? (
                  <Box>
                    <Box className={cx(customCSS.fileName)}>
                      {postData.documents.length && postData.documents[0].title}
                    </Box>
                    <Box className={cx(customCSS.fileSize)}>
                      {formatBytes(postData.documents.length && postData.documents[0].size)}
                    </Box>
                  </Box>
                ) : null}
                <div
                  className={cx(customCSS.newsbody)}
                  dangerouslySetInnerHTML={{ __html: postData && postData.text }}></div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {postType === 'pageBuilder' && postData && (
        <Box className={cx(customCSS.desktopPreviewWrap)}>
          <Grid container alignItems="end">
            <Grid item md={12}>
              <Box>
                <Box className={cx(customCSS.newshead)}>
                  <span className={cx(customCSS.date)}>
                    {postData && postData.releaseDate && format(new Date(postData.releaseDate), 'PPP')}
                  </span>
                  <h3 className={cx(customCSS.newsdesktopHeading)}>{postData && postData.title}</h3>
                  <Stack direction="row" columnGap={1}>
                    {postData &&
                      postData.tagIds &&
                      postData.tagIds.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value.name} bgcolor="rgba(0, 0, 0, 0.05)" color="#000" />
                        );
                      })}
                    {postData &&
                      postData.newTags &&
                      postData.newTags.map((item, index) => {
                        return (
                          <TagBasic key={index} content={item.value} bgcolor="rgba(244, 231, 212, 1)" color="#000" />
                        );
                      })}
                  </Stack>
                </Box>
                <hr />
                <br />
                <div
                  className={cx(customCSS.newsbody)}
                  dangerouslySetInnerHTML={{ __html: postData && postData.text }}></div>
                {postData.components &&
                  postData.components.map(item => {
                    switch (item.component) {
                      case 'Content':
                        return (
                          <div
                            key={item.key}
                            className={cx(customCSS.newsbody)}
                            dangerouslySetInnerHTML={{ __html: item && item.value }}></div>
                        );
                      case 'Contact':
                        return (
                          <div key={item.key} className={cx(customCSS.newsbody)}>
                            <h4>Contact</h4>
                            <ul>
                              <li>Name: {item.value?.name || ''}</li>
                              <li>Position: {item.value?.position || ''}</li>
                              <li>Phone: {item.value?.phoneNumber || ''}</li>
                              <li>Email: {item.value?.emailAddress || ''}</li>
                            </ul>
                          </div>
                        );
                      case 'Video':
                        return (
                          <div key={item.key} className={cx(customCSS.newsbody)}>
                            <h4>Video: {item.value?.title || ''}</h4>
                            <p>{item.value?.description || ''}</p>
                            {item.value?.video && (
                              <Box>
                                <Box className={cx(customCSS.fileName)}>
                                  {item.value.video.length && item.value.video[0].name}
                                </Box>
                                <Box className={cx(customCSS.fileSize)}>
                                  {formatBytes(item.value.video.length && item.value.video[0].size)}
                                </Box>
                              </Box>
                            )}
                          </div>
                        );
                      case 'Document Upload':
                        return (
                          <div key={item.key} className={cx(customCSS.newsbody)}>
                            <h4>Document: {item.value?.title || ''}</h4>
                            <p>{item.value?.description || ''}</p>
                            {item.value?.files && (
                              <Box>
                                <Box className={cx(customCSS.fileName)}>
                                  {item.value.files.length && item.value.files[0].name}
                                </Box>
                                <Box className={cx(customCSS.fileSize)}>
                                  {formatBytes(item.value.files.length && item.value.files[0].size)}
                                </Box>
                              </Box>
                            )}
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* postType===3 for Content Page Preview */}
    </Box>
  );
};

export default DesktopPreview;

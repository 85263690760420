/** @format */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IconLabelButtons from 'src/components/Buttons';
import { RightArrow } from 'src/components/Icons';
import { SearchHeader } from 'src/components/PageHeader';
import useAuth from 'src/hooks/useAuth';
import { getCandidatesList } from 'src/store/action/admin.Action';
import { getTopicListForAdmin } from 'src/store/action/topic.Action';
import { getUserList } from 'src/store/action/user.Action';

export default function Admin() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const candidatesList = useSelector(state => state.adminReducer.candidatesList);
  const topicList = useSelector(state => state.topicReducer.topicListForAdmin);
  const { totalUser, userList } = useSelector(state => state.userReducer);

  useEffect(() => {
    if (candidatesList?.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en',
        limit: 50
      };
      dispatch(getCandidatesList(payload));
    }
    if (topicList?.length === 0) {
      dispatch(
        getTopicListForAdmin({
          lang: user ? user.locale : 'en'
        })
      );
    }
    if (userList?.length === 0) {
      dispatch(
        getUserList({
          limit: 50,
          order: 'created',
          orderDirection: 'DESC',
          userClauses: [],
          userGroup: [],
          market: []
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const updatedCandidates =
    candidatesList?.length > 0
      ? [...candidatesList]
          .sort((a, b) => {
            return b.created - a.created;
          })
          .map(c => c.created)
      : '';
  const latestUpdate = updatedCandidates ? new Date(updatedCandidates[0] * 1000) : new Date();

  return (
    <div className="admin-user-nav-open">
      <SearchHeader heading="Admin" />
      <h4 className="page_header_topicsSubHeading">{t('admin.subHeading')}</h4>
      <div className="admin-lists">
        <Link to="/admin/candidates" className="admin-item">
          <div>
            <h2 className="admin-item-title">{t('admin.adminMenu1')}</h2>
            <p className="admin-item-update-time">
              {candidatesList?.length} {t('admin.newCandidate')} • updated {latestUpdate.toISOString().split('T')[0]}
            </p>
            <IconLabelButtons
              type="button"
              onClick={() => {}}
              className="admin-item-btn noLabel dark"
              endIcon={<RightArrow />}
            />
          </div>
        </Link>
        {user?.roles?.includes('Extranet Useradmin') && (
          <Link to="/admin/user" className="admin-item">
            <h2 className="admin-item-title">{t('admin.adminMenu2')}</h2>
            <p className="admin-item-update-time">
              {totalUser} {t('admin.users')} • updated {latestUpdate.toISOString().split('T')[0]}
            </p>
            <IconLabelButtons
              type="button"
              onClick={() => {}}
              className="admin-item-btn noLabel dark"
              endIcon={<RightArrow />}
            />
          </Link>
        )}
        <Link to="/admin/topics" className="admin-item">
          <h2 className="admin-item-title">{t('admin.adminMenu3')}</h2>
          <p className="admin-item-update-time">
            {topicList?.length} {t('admin.topics')} • updated {latestUpdate.toISOString().split('T')[0]}
          </p>
          <IconLabelButtons
            type="button"
            onClick={() => {}}
            className="admin-item-btn noLabel dark"
            endIcon={<RightArrow />}
          />
        </Link>
      </div>
    </div>
  );
}

import { css, cx } from "@emotion/css";
import { SwipeableDrawer } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { previewOpen } from "src/store/action/editor.Action";
import ContentPreview from "./contentPreview";

const PreviewLayer = props => {
    const w = window.innerWidth;
    const dispatch = useDispatch();
    const previewOpenState = useSelector(state => state.editorReducer.previewOpenState);

    const togglePreview = (openEvent) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        dispatch(previewOpen(openEvent))
    };
    const customCSS = {
        previewLayer: css`
            &.MuiPaper-root{
                background:#FFFDFA;
                padding:40px 60px;
                @media screen and (max-width:768px){padding:32px 20px;}
                width:${w >= 768 ? '90%' : '100%'};
                height:${w >= 768 ? '100%' : '90%'};
                border-radius:${w >= 768 ? '48px 0 0 48px' : '48px 48px 0 0'};
            }
        `
    };

    
    return (
        <SwipeableDrawer
            anchor={w >= 768 ? 'right' : 'bottom'}
            onClose={togglePreview(false)}
            onOpen={togglePreview(true)}
            PaperProps={{ className: `custom-swipeable ${cx(customCSS.previewLayer)}` }}
            ModalProps={{ sx: { zIndex: 6000 } }}
            open={previewOpenState}
        >
            <ContentPreview postType={props.postType} postData={props.postData} />
        </SwipeableDrawer>
    )
}

export default PreviewLayer;
/** @format */

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextEditor from 'src/components/reachTextEditor';
import { ComponentTools } from './comTools';
import { useState } from 'react';
import { COMPONENT_ACTION } from 'src/constants';

export default function ComContent({ components, actionComponent, com, index }) {
  const { t } = useTranslation();
  const [editorError, setEditorError] = useState(false);
  const setData = (name, data) => {
    actionComponent(COMPONENT_ACTION.UPDATE_VALUE_COMPONENT, { component: com, value: data });
  };
  const editorValidate = data => {
    if (data === '') {
      setEditorError(true);
    } else {
      setEditorError(false);
    }
  };

  return (
    <Box className="pageBuilder-contentFieldsWrap">
      <Box className="field-label">
        <span>{t('editorContent.content.content')}*</span>
        <ComponentTools components={components} action={actionComponent} component={com} />
      </Box>
      <Box className="pageBuilder-textEditor">
        <TextEditor
          setEditorData={setData}
          name={`text-${index}`}
          editorClass={'' ? 'text-area_wrapper error' : 'text-area_wrapper'}
          dataValue={com.value}
          fieldValidate={editorValidate}
          textError={editorError}
        />
      </Box>
    </Box>
  );
}

/** @format */

import { Box, TextareaAutosize } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { AutoCompleteSelectLight } from 'src/components/Dropdowns/LightDropdown';
import { CheckIcon, DeleteIcon } from 'src/components/Icons';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import MultiSelectDropdownLight from 'src/components/MultiSelectDropdowns/MultiSelectDropdownLight';
import SwitchLabel from 'src/components/switch/SwitchLabel';
import { BRANDS } from 'src/constants';
import {
  adminTopicsDeleteTopic,
  adminTopicsEdit,
  adminTopicsEditorOpen,
  createTopic,
  updateTopic
} from 'src/store/action/adminTopics.Action';
import { flatternTopics } from '../topics';
import axiosInstance from 'src/utils/axios';
import { getTopicList, getTopicListForAdmin } from 'src/store/action/topic.Action';
import useAuth from 'src/hooks/useAuth';

const noParentItem = { id: 0, value: null, name: 'No parent', level: 0 };

export default function AdminTopicsEditor({ toggleDrawer }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({});
  const [errors, setErrors] = useState([]);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const topicList = useSelector(state => state.topicReducer.topicListForAdmin);
  const idTopicEdit = useSelector(state => state.adminTopicsReducer.idTopicEdit);
  const errorsResponse = useSelector(state => state.adminTopicsReducer.errors);
  const [loading, setLoading] = useState(false);

  const flatTopics = flatternTopics(topicList);

  useEffect(() => {
    const topic = idTopicEdit ? flatTopics.find(t => t.id === idTopicEdit) : null;
    if (topic && topicList?.length) {
      // const parent = topic.parents?.length ? flatTopics.find(t => t.id === topic.parents[0]) : null;
      setPostData({
        id: topic.id,
        name: topic.name,
        displayName: topic.displayName,
        description: topic.description,
        brand: topic.brand,
        roles: topic.roles,
        parents: topic.parents || null,
        published: topic.published
      });
    } else {
      setPostData({
        name: '',
        displayName: null,
        description: '',
        brand: null,
        roles: [],
        parents: [],
        published: false
      });
    }
  }, [idTopicEdit, topicList]);

  const fieldValidate = e => {
    const { name } = e.target;
    if (name === 'name') {
      if (e.target.value === '') {
        setErrors([...errors, { field: 'name', message: 'Invalid name' }]);
      } else {
        setErrors([...errors].filter(e => e.message !== 'Invalid name'));
      }
    }
  };
  const onChange = e => {
    if (e.currentTarget.name) {
      setPostData({
        ...postData,
        [e.currentTarget.name]: e.currentTarget.value
      });
    }
  };
  const setDataByName = (name, value) => {
    setPostData({
      ...postData,
      [name]: value
    });
  };
  const togglePublished = e => {
    setPostData({
      ...postData,
      published: !postData.published
    });
  };

  const topicListSelection = topicList.reduce(
    (prev, cur) => {
      // Avoid the allowing topic to be assigned to itself as parent
      if (idTopicEdit && idTopicEdit === cur.id) return prev;

      const newArr = [...prev, { id: cur.id, value: cur.id, name: cur.name, level: 1 }];
      if (cur.children?.length) {
        cur.children.forEach(c => {
          if (idTopicEdit && idTopicEdit !== c.id) {
            newArr.push({ id: c.id, value: c.id, name: c.name, level: 2 });
          }
        });
      }
      return newArr;
    },
    [noParentItem]
  );

  const handleSubmitForm = async () => {
    setLoading(true);
    if (idTopicEdit) {
      const { id, ...restData } = postData;
      await dispatch(updateTopic({ lang: user?.locale || 'en', id: id, data: restData }));
    } else {
      await dispatch(createTopic({ lang: user?.locale || 'en', data: postData }));
    }
    await dispatch(getTopicList({ lang: user?.locale || 'en' }));
    await dispatch(getTopicListForAdmin({ lang: user?.locale || 'en' }));
    dispatch(adminTopicsEditorOpen(false));
    dispatch(adminTopicsEdit(null));
    setLoading(false);
  };
  const brandOptions = Object.keys(BRANDS).map(b => ({ id: BRANDS[b], name: b }));

  return (
    <Box className="adminTopics-editor">
      <h1>{idTopicEdit ? postData?.name : t('adminTopics.createTopic')}</h1>
      <Box className="adminTopics-editor-main-content">
        <h4 className="adminTopics-editor-title">{t('adminTopics.generalInformation')}</h4>
        <Box className="adminTopics-editor-form">
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.name')}*</Box>
            <Box className="field-input">
              <OutlinedInputFieldsLight
                placeholder="Name*"
                name="name"
                onChange={onChange}
                value={postData?.name || ''}
                onBlur={fieldValidate}
                error={errors.findIndex(e => e.field === 'name') > -1}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.displayName')}</Box>
            <Box className="field-input">
              <OutlinedInputFieldsLight
                placeholder="Display name"
                name="displayName"
                onChange={onChange}
                value={postData?.displayName || ''}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.description')}</Box>
            <Box className="field-input">
              <TextareaAutosize
                minRows={3}
                name="description"
                placeholder="Beschreibung"
                className="text-area"
                onChange={onChange}
                value={postData?.description || ''}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.status')}</Box>
            <Box className="field-input">
              <SwitchLabel
                label={t('adminTopics.published')}
                name="published"
                onChange={togglePublished}
                checked={postData.published || false}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.brand')}</Box>
            <Box className="field-input">
              <AutoCompleteSelectLight
                options={brandOptions}
                setdatabyname={(name, value) => setDataByName(name, value.id)}
                name="brand"
                value={postData.brand ? brandOptions.find(b => b.id === postData.brand) || null : null}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box key={option.name} component="li" {...optionProps}>
                      {option.name}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.role')}</Box>
            <Box className="field-input">
              <MultiSelectDropdownLight
                className="multiselect-dropdown light field-input-select"
                listdata={rolesList}
                setdatabyname={(name, value) =>
                  setDataByName(
                    name,
                    value.map(v => v.id)
                  )
                }
                name="roles"
                defaultValue={postData?.roles?.length ? rolesList.filter(r => postData.roles.includes(r.id)) : []}
                value={postData?.roles?.length ? rolesList.filter(r => postData.roles.includes(r.id)) : []}
              />
            </Box>
          </Box>
          <Box className="content-field-wrap">
            <Box className="field-label">{t('adminTopics.parentTopic')}</Box>
            <Box className="field-input">
              <AutoCompleteSelectLight
                options={topicListSelection}
                setdatabyname={(name, value) => setDataByName(name, [value.id])}
                name="parents"
                value={
                  postData.parents?.length
                    ? flatTopics.find(t => t.id === postData.parents[0]) || noParentItem
                    : noParentItem
                }
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box
                      key={option.value}
                      component="li"
                      {...optionProps}
                      sx={{ marginLeft: option.level === 2 ? 3 : 0 }}>
                      {option.name}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="adminTopics-editor-buttons">
        <IconLabelButtons
          disabled={loading}
          type="button"
          label={t('adminTopics.cancel')}
          className={`customButton customButton-cancel`}
          bgcolor="rgba(0, 0, 0, 0.05) !important"
          onClick={() => {
            dispatch(adminTopicsEditorOpen(false));
            dispatch(adminTopicsEdit(null));
          }}
        />
        {/* Temp hidden delete button */}
        {/* {idTopicEdit && (
          <IconLabelButtons
            disabled={errors.length > 0 || loading}
            endIcon={<DeleteIcon />}
            type="button"
            label={t('adminTopics.delete')}
            className="customButton dark"
            onClick={() => {
              dispatch(adminTopicsDeleteTopic(idTopicEdit));
            }}
          />
        )} */}
        <IconLabelButtons
          disabled={errors.length > 0 || loading}
          endIcon={<CheckIcon />}
          type="button"
          label={errors.length > 0 || loading ? t('placeholder.loading') : t('adminTopics.saveChanges')}
          className="customButton dark"
          onClick={handleSubmitForm}
        />
      </Box>
    </Box>
  );
}

/** @format */

import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import OutlinedInputFieldsLight from 'src/components/Inputs/OutlinedInputLight';
import OutlineInputWithIconsLight from 'src/components/Inputs/OutlinedInputWithIconLight';
import IconLabelButtons from 'src/components/Buttons';
import { CheckIcon, CheckCircle } from 'src/components/Icons';
import { useDispatch } from 'react-redux';
import { changePassword, clear } from 'src/store/action/profile.Action';
import { VALIDATE_REGEX } from '../../constants';

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [password, setPassword] = useState(undefined);
  const [confirmPassword, setConfirmPassword] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [successMsg, setSuccessMsg] = useState(undefined);
  const [specialCase, setSpecialCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [length, setLength] = useState(false);

  const savePassword = () => {
    let payload = {
      uuid: user.uuid,
      password: password
    };
    dispatch(changePassword(payload)).then(response => {
      if (response) {
        setSuccessMsg(t('account.successMsg'));
        dispatch(clear());
        setTimeout(() => {
          reset();
        }, 3000);
      }
    });
  };

  const reset = () => {
    setConfirmPassword(undefined);
    setPassword(undefined);
    setSuccessMsg(undefined);
  };

  const passwordValidate = e => {
    const { value } = e.target;
    setPassword(value);
    validateHighlight(value);
    if (confirmPassword && confirmPassword === value) {
      let validation = regexValidate(value);
      if (validation) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(t('account.reqPasswordNotFulfil'));
      }
    } else if (confirmPassword) {
      setErrorMsg(t('account.passwordNotMatch'));
    } else if (!confirmPassword) {
      setErrorMsg(undefined);
    }
  };

  const confirmPasswordValidate = e => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (password === value) {
      let validation = regexValidate(value);
      if (validation) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg(t('account.reqPasswordNotFulfil'));
      }
    } else {
      setErrorMsg(t('account.passwordNotMatch'));
    }
  };

  const regexValidate = value => {
    return VALIDATE_REGEX.password.test(value);
  };

  const validateHighlight = value => {
    let specialCaseLetters = /[!,;`~'":{}<>^/@#$%&*?|\\[\])(+=._-]/;

    if (value.match(specialCaseLetters)) {
      setSpecialCase(true);
    } else {
      setSpecialCase(false);
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (value.match(upperCaseLetters)) {
      setUpperCase(true);
    } else {
      setUpperCase(false);
    }

    // Validate numbers
    let numbers = /[0-9]/g;
    if (value.match(numbers)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    // Validate length
    if (value.length >= 8) {
      setLength(true);
    } else {
      setLength(false);
    }
  };

  return (
    <Fragment>
      <h2 className="page_title">{t('account.title')}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className="sub_head">{t('account.email_Title')}</h4>
          <Grid container sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <Grid item xs={11} lg={3} className="lbl_txt">
              {t('account.email')}
            </Grid>
            <Grid item xs={11} lg={8}>
              <OutlinedInputFieldsLight
                placeholder={t('account.email')}
                // onChange={setData}
                value={user?.email || ''}
                name="email"
                fullWidth
                margin="normal"
                disabled
                inputlabelprops={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
          <h4 className="sub_head">{t('account.changePassword')}</h4>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('account.newPassword')}
            </Grid>
            <Grid item xs={11} lg={8}>
              <OutlineInputWithIconsLight
                placeholder={t('account.newPassword')}
                onChange={e => passwordValidate(e)}
                value={password || ''}
                name="password"
                fullWidth
                margin="normal"
                inputlabelprops={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3} className="lbl_txt"></Grid>
            <Grid item xs={11} lg={8}>
              <div className={length ? 'instruction fulfilled' : 'instruction'}>
                {' '}
                <CheckCircle color={length ? 'rgb(37,189,4)' : 'rgb(107,107,107)'} width={15} height={15} />{' '}
                {t('account.instruction_1')}
              </div>
              <div className={upperCase ? 'instruction fulfilled' : 'instruction'}>
                {' '}
                <CheckCircle color={upperCase ? 'rgb(37,189,4)' : 'rgb(107,107,107)'} width={15} height={15} />{' '}
                {t('account.instruction_2')}
              </div>
              <div className={number ? 'instruction fulfilled' : 'instruction'}>
                {' '}
                <CheckCircle color={number ? 'rgb(37,189,4)' : 'rgb(107,107,107)'} width={15} height={15} />{' '}
                {t('account.instruction_3')}
              </div>
              <div className={specialCase ? 'instruction fulfilled' : 'instruction'}>
                {' '}
                <CheckCircle color={specialCase ? 'rgb(37,189,4)' : 'rgb(107,107,107)'} width={15} height={15} />{' '}
                {t('account.instruction_4')}
              </div>
            </Grid>
            <Grid item xs={12} lg={3} className="lbl_txt">
              {t('account.confirmPassword')}
            </Grid>
            <Grid item xs={11} lg={8}>
              <OutlineInputWithIconsLight
                placeholder={t('account.confirmPassword')}
                fullWidth
                value={confirmPassword || ''}
                onChange={e => confirmPasswordValidate(e)}
                name="confirmPassword"
                sx={{ maxWidth: '100%' }}
                margin="normal"
                inputlabelprops={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3} className="lbl_txt"></Grid>
            {errorMsg && (
              <Grid item xs={11} lg={8}>
                <span className="errorMsg">{errorMsg}</span>
              </Grid>
            )}
            {successMsg && (
              <Grid item xs={11} lg={8}>
                <span className="successMsg">{successMsg}</span>
              </Grid>
            )}
            {!errorMsg && !successMsg && (
              <Grid item xs={11} lg={8} sx={{ visibility: 'hidden' }}>
                <span className="placeholderMsg">&nbsp;</span>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={11}>
              <div style={{ marginTop: 15, textAlign: 'right' }}>
                {' '}
                <IconLabelButtons
                  type="button"
                  endIcon={<CheckIcon color="rgb(255,255,255)" />}
                  label={t('account.cta_3')}
                  className="customButton dark"
                  onClick={savePassword}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Account;
